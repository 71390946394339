import { useNavigationLinkWithWorkspace } from '@src/client/hooks';
import { ErrorTags } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';
import { getUseQueryOptions } from '@src/client/lib/api/utils';
import { getWalletBalance } from '@src/client/modules/wallet/api/queries';
import { walletBalance } from '@src/client/modules/wallet/atoms';
import { PerceptCoinIcon } from '@src/client/modules/wallet/WalletIcons';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

export default function WalletMenu() {
  const [balance, setBalance] = useRecoilState(walletBalance);
  const navigate = useNavigate();
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();

  useQuery(['walletBalance'], () => getWalletBalance(), {
    ...getUseQueryOptions(),
    onSuccess: (res) => {
      setBalance(res);
    },
    onError: (err: Error) => {
      Tracker.trackError(err, ErrorTags.FETCH_WALLET_BALANCE);
    },
  });

  if (balance)
    return (
      <button
        type="button"
        className="flex items-center bg-neutral-dark h-10 px-2 rounded-xl ml-3 space-x-2 hover:ring-1"
        onClick={() => navigate(getLinkWithWorkspace(`wallet`))}
      >
        <PerceptCoinIcon className="h-6 w-fit" />
        <div className="flex flex-col text-xs leading-tight text-foreground-light">
          <div className="flex space-x-1 font-bold">
            <p>{`${balance}`}</p>
            <p>credits</p>
          </div>
          <p>Wallet</p>
        </div>
      </button>
    );
}
