/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import CreatedByFilter from '@src/client/components/filters-and-selectors/created-by-filter';
import ReportTypeFilter from '@src/client/components/filters-and-selectors/report-type-filter';
import { useNavigationLinkWithWorkspace } from '@src/client/hooks';
import { ErrorTags } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';
import { getSearchItems } from '@src/client/lib/api/queries/common';
import { PlatformItemType } from '@src/client/lib/api/types/request';
import { ReportType, SearchItem } from '@src/client/lib/api/types/response';
import { getNavigationPath } from '@src/client/lib/utils';
import { Dialog } from '@src/client/ui-library/dialog';
import { DebouncedInput } from '@src/client/ui-library/input';
import { ColumnDef, VirtualTable } from '@src/client/ui-library/table';
import { Tooltip } from '@src/client/ui-library/tooltip';
import { formatDistanceToNow } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { SEARCH_PAGE_SIZE } from '../../modules/home/constants';
import { searchCreatedByFilterState } from '../filters-and-selectors/created-by-filter/state';
import { searchActiveReportTypeFiltersState } from '../filters-and-selectors/report-type-filter/state';

interface Props {
  open: boolean;
  onClose: () => void;
}

const ReportImages = {
  [ReportType.INSIGHT]: '/images/v2/insights-small.png',
  [ReportType.FUNNEL]: '/images/v2/funnels-small.png',
  [ReportType.FLOW]: '/images/v2/flows-small.png',
  [ReportType.RETENTION]: '/images/v2/retention-small.png',
};

const ItemImages = {
  [PlatformItemType.COHORT]: '/images/v2/cohorts-small.png',
  [PlatformItemType.RETENTION]: '/images/v2/retention-small.png',
  [PlatformItemType.DASHBOARD]: '/images/v2/dashboard-small.png',
  [PlatformItemType.REPORT]: '/images/v2/insights-small.png',
  [PlatformItemType.ALERT]: '/images/v2/alerts-small.png',
  [PlatformItemType.CAMPAIGN]: '/images/v2/alerts-small.png',
  [PlatformItemType.EVENT]: '/images/v2/insights-small.png',
  [PlatformItemType.CUSTOM_EVENT]: '/images/v2/insights-small.png',
};

export default function GlobalSearchModal({ open, onClose }: Props) {
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();
  const [activeReportTypes] = useRecoilState(searchActiveReportTypeFiltersState);
  const resetReportTypeFilters = useResetRecoilState(searchActiveReportTypeFiltersState);
  const [selectedCreator] = useRecoilState(searchCreatedByFilterState);
  const resetSelectedCreators = useResetRecoilState(searchCreatedByFilterState);
  const [pageIndex, setPageindex] = useState(0);

  useEffect(() => {
    resetReportTypeFilters();
    resetSelectedCreators();
  }, []);

  const [reportsResponse, setReportsResponse] = useState<SearchItem[]>([]);
  const [reportsSearchText, setReportsSearchText] = useState<string>('');

  const loadAllReports = useMutation(
    async () =>
      getSearchItems({
        createdBy: selectedCreator.map((val) => val.label),
        itemType: activeReportTypes.map((val) => val.itemType),
        reportType: activeReportTypes
          .filter((val) => val.reportType !== null)
          .map((val) => val.reportType as ReportType),
        filterText: reportsSearchText,
        limit: SEARCH_PAGE_SIZE,
        offset: pageIndex * SEARCH_PAGE_SIZE,
      }),
    {
      onSuccess: (response) => {
        const newResponse = response.sort((a, b) => b.createdAt - a.createdAt);
        if (pageIndex === 0) {
          setReportsResponse(newResponse);
        } else {
          setReportsResponse([...reportsResponse, ...newResponse]);
        }
      },
      onError: (error: Error) => {
        Tracker.trackError(error, ErrorTags.SEARCH_ERROR);
      },
    },
  );

  useEffect(() => {
    loadAllReports.mutate();
  }, [pageIndex]);

  useEffect(() => {
    if (pageIndex !== 0) {
      setPageindex(0);
    } else {
      loadAllReports.mutate();
    }
  }, [reportsSearchText, activeReportTypes, selectedCreator]);

  const columns = useMemo<ColumnDef<SearchItem>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        cell: ({ row }) => (
          <a
            href={getLinkWithWorkspace(
              getNavigationPath(row.original.itemType, row.original.reportType, row.original.itemExternalId),
            )}
          >
            <div className="flex items-center">
              <img
                className="w-8"
                src={
                  row.original.reportType
                    ? ReportImages[row.original.reportType]
                    : row.original.itemType
                    ? ItemImages[row.original.itemType]
                    : '/images/v2/insights-small.png'
                }
                alt={`${row.original.reportType} icon`}
              />
              <Tooltip content={<p>{row.original.name}</p>}>
                <div className="ml-2 max-w-100">
                  <p className="text-xs font-medium truncate">{row.original.name}</p>
                  <p className="text-xs font-regular truncate">{row.original.reportType ?? row.original.itemType}</p>
                </div>
              </Tooltip>
            </div>
          </a>
        ),
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
      },
      {
        accessorKey: 'createdAt',
        meta: {
          sortable: true,
        },
        header: 'Created At',
        cell: ({ row }) => <span>{formatDistanceToNow(row.getValue('createdAt'), { addSuffix: true })}</span>,
      },
      {
        accessorKey: 'createdBy',
        header: 'Created By',
        filterFn: (row, id, value) => value.includes(row.getValue(id)),
      },
    ],
    [],
  );

  return (
    <Dialog
      open={open}
      onOpenChange={onClose}
      classNames={{ dialogContent: 'h-[600px] w-screen w-[80%] max-w-full transform-none left-[10%] top-[15%]' }}
    >
      <div className="flex flex-col h-full">
        <div className="mb-4">
          <h2 className="text-lg font-semibold">Search Existing Reports</h2>
        </div>
        <div className="flex-grow grid grid-cols-4 gap-4 h-auto">
          <div className="col-span-3 flex flex-col h-auto">
            <DebouncedInput
              placeholder="Search for insights, cohorts, or funnels ... "
              className="mb-4"
              value={reportsSearchText}
              onChange={(val) => setReportsSearchText(val as string)}
            />
            <VirtualTable
              useWindowScroll={false}
              columns={columns}
              data={reportsResponse ?? []}
              loading={loadAllReports.isLoading}
              onEndReached={() => setPageindex(pageIndex + 1)}
              roundedTable
            />
          </div>
          <div className="col-span-1 max-w-[300px] flex flex-col space-y-5 border border-border rounded-xl p-4 h-auto">
            <p className="text-md font-semibold">Filters</p>
            <ReportTypeFilter reportTypeFiltersState={searchActiveReportTypeFiltersState} />
            <CreatedByFilter createdByFilterState={searchCreatedByFilterState} />
          </div>
        </div>
      </div>
    </Dialog>
  );
}
