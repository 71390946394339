/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-unused-prop-types */
import ApiErrorView from '@src/client/components/api-error-view';
import { createdByFilterState } from '@src/client/components/filters-and-selectors/created-by-filter/state';
import { activeReportTypeFiltersState } from '@src/client/components/filters-and-selectors/report-type-filter/state';
import { ReportTypeDisplyInfo, ReportTypes } from '@src/client/helpers/constants';
import { useIsDemoWorkspace, useNavigationLinkWithWorkspace, usePrevious } from '@src/client/hooks';
import { ErrorTags, EventProperty } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';
import { deleteReport } from '@src/client/lib/api/mutations/common';
import { getRecentlyViewed } from '@src/client/lib/api/queries/common';
import { QueryKeys } from '@src/client/lib/api/queries/keys';
import { RecentlyViewedItemResponse, ReportType } from '@src/client/lib/api/types/response';
import { getNavigationPath, isLengthyArray } from '@src/client/lib/utils';
import { userInfoState } from '@src/client/recoil/atoms';
import AlertDialog from '@src/client/ui-library/alert-dialog';
import { Button } from '@src/client/ui-library/button';
import { DeleteIcon } from '@src/client/ui-library/icons/DashboardIcons';
import { SpinLoader } from '@src/client/ui-library/loaders';
import { ColumnDef, VirtualTable } from '@src/client/ui-library/table';
import { useToast } from '@src/client/ui-library/toast/use-toast';
import { Tooltip } from '@src/client/ui-library/tooltip';
import { PaginationState, Row } from '@tanstack/react-table';
import { formatDistanceToNow } from 'date-fns';
import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { RECENTLY_VIEWED_PAGE_SIZE } from '../constants';
import { ListViewType, reportsListViewState } from '../state';
import CreateReportPills from './CreateReportPills';
import ListViewToggler from './ListViewToggler';
import ReportsGrid from './ReportsGrid';

const ReportImages = {
  [ReportType.INSIGHT]: '/images/v2/insights-small.png',
  [ReportType.FUNNEL]: '/images/v2/funnels-small.png',
  [ReportType.FLOW]: '/images/v2/flows-small.png',
  [ReportType.RETENTION]: '/images/v2/retention-small.png',
};

// NOTE: Update Apis here and connect to filter state
interface Props {
  currentUserOnly: boolean;
}

export default function ReportsView({ currentUserOnly }: Props) {
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();
  const userInfo = useRecoilValue(userInfoState);
  const [reportsResponse, setReportsResponse] = useState<RecentlyViewedItemResponse[]>([]);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedReportId, setSelectedReportId] = useState<string>();
  const { toast } = useToast();
  const [{ pageIndex }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: RECENTLY_VIEWED_PAGE_SIZE,
  });
  const reportTypes = useRecoilValue(activeReportTypeFiltersState);
  const createdBy = useRecoilValue(createdByFilterState);
  const viewState = useRecoilValue(reportsListViewState);

  const lastReportTypes = usePrevious(reportTypes);
  const lastCreatedBy = usePrevious(createdBy);

  const hasParamsChanged = useRef<boolean>(false);
  const isDemoWorkspace = useIsDemoWorkspace();

  const showDeleteDialogBox = (reportId: string) => {
    setSelectedReportId(reportId);
    setShowDeleteDialog(true);
  };

  const closeDeleteDialogBox = () => {
    setShowDeleteDialog(false);
    setSelectedReportId(undefined);
  };

  const deleteReportReq = useMutation(deleteReport, {
    onSuccess: (response) => {
      setReportsResponse(reportsResponse.filter((report) => report.itemExternalId !== response.itemExternalId));
      setShowDeleteDialog(false);
    },
    onError: (error: Error, variable) => {
      Tracker.trackError(error, ErrorTags.MY_REPORT_DELETE_ERROR, {
        [EventProperty.ItemExternalId]: variable?.itemExtId,
      });
      toast({ variant: 'danger', title: error.name, description: error.message });
    },
  });

  const handleDelete = () => {
    if (selectedReportId) deleteReportReq.mutate({ itemExtId: selectedReportId });
  };

  const getRecentlyViewedData = useMutation(
    [QueryKeys.GetRecentlyViewed, currentUserOnly, reportTypes, createdBy, pageIndex],
    () =>
      getRecentlyViewed({
        // configStatus: ItemStatus.APPROVED,
        limit: RECENTLY_VIEWED_PAGE_SIZE,
        offset: pageIndex * RECENTLY_VIEWED_PAGE_SIZE,
        reportType: isLengthyArray(reportTypes)
          ? (reportTypes.filter((i) => i.reportType).map((rt) => rt.reportType) as ReportType[])
          : [],
        itemType: isLengthyArray(reportTypes) ? reportTypes.map((rt) => rt.itemType) : [],
        createdBy: currentUserOnly ? null : isLengthyArray(createdBy) ? createdBy.map((d) => d.label) : null,
        currentUserOnly,
      }),
    {
      onSuccess: (response) => {
        setCanLoadMore(response.length >= RECENTLY_VIEWED_PAGE_SIZE);
        setReportsResponse([...reportsResponse, ...response]);
      },
      onError: (error: Error) => {
        Tracker.trackError(error, ErrorTags.HOME_RECENTLY_VIEWED_FETCH_ERROR);
      },
    },
  );

  const NoReportsPlaceholder = (
    <div className="flex flex-col justify-center items-center h-full">
      <img src="/images/v2/no_reports.png" width={300} alt="No Reports illustration" />
      <div className="text-center items-center px-3 py-5">
        <p className="font-bold text-md">No reports created</p>
        <p className="m-1 text-sm">Click on the below options to create your first report</p>
      </div>
      <div className="flex gap-4">
        {ReportTypes.map((reportType) => {
          const data = ReportTypeDisplyInfo[reportType];
          return (
            <Link to={getLinkWithWorkspace(data.path)} key={data.path}>
              <div className="p-3.5 rounded-lg w-[225px] h-[180px] flex flex-col" style={{ background: data.color }}>
                <div className="flex items-center mb-3">
                  <img src={data.imageSrc} className="w-8 h-8 mr-2" alt={`${data.title} icon`} />
                  <p className="text-md font-semibold text-gray-950">{data.title}</p>
                </div>
                <p className="text-xs font-normal text-gray-700 mb-4">{data.description}</p>
                <div className="flex-grow" />
                <p className="text-primary-dark text-sm font-semibold">{`+ Create ${data.title}`}</p>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );

  useEffect(() => {
    getRecentlyViewedData.mutate();
  }, []);

  useEffect(() => {
    if (
      reportTypes &&
      createdBy &&
      lastCreatedBy &&
      lastReportTypes &&
      (!isEqual(reportTypes, lastReportTypes) || !isEqual(createdBy, lastCreatedBy))
    ) {
      setReportsResponse([]);
      setPagination({ pageIndex: 0, pageSize: RECENTLY_VIEWED_PAGE_SIZE });
      hasParamsChanged.current = true;
    }
  }, [reportTypes, createdBy]);

  useEffect(() => {
    // defaultrun is set to false by filter change in above effect
    if (pageIndex > 0 || (pageIndex === 0 && hasParamsChanged.current === true)) {
      getRecentlyViewedData.mutate();
      hasParamsChanged.current = false;
    }
  }, [pageIndex, hasParamsChanged.current]);

  const loadMore = useCallback(() => {
    if (!canLoadMore) return;
    setPagination({ pageIndex: pageIndex + 1, pageSize: RECENTLY_VIEWED_PAGE_SIZE });
  }, [canLoadMore, pageIndex]);

  const columns = useMemo<ColumnDef<RecentlyViewedItemResponse>[]>(
    () =>
      [
        {
          accessorKey: 'name',
          header: 'Name',
          meta: {
            sortable: true,
          },
          cell: ({ row }: { row: Row<RecentlyViewedItemResponse> }) => (
            <Link
              to={getLinkWithWorkspace(
                getNavigationPath(row.original.itemType, row.original.reportType, row.original.itemExternalId),
              )}
            >
              <div className="flex items-center">
                <img
                  className="w-8"
                  src={
                    row.original.reportType && ReportImages[row.original.reportType]
                      ? ReportImages[row.original.reportType]
                      : '/images/v2/dashboard-small.png'
                  }
                  alt={`${row.original.reportType} icon`}
                />
                <Tooltip content={<p>{row.original.name}</p>}>
                  <div className="ml-2">
                    <p className="text-xs font-medium truncate">{row.original.name}</p>
                    <p className="text-xs font-regular truncate max-w-300">{row.original.description}</p>
                  </div>
                </Tooltip>
              </div>
            </Link>
          ),
        },
        {
          accessorKey: 'lastOpenedAt',
          header: 'Last opened at',
          meta: {
            sortable: true,
          },
          cell: ({ row }: { row: Row<RecentlyViewedItemResponse> }) => (
            <span>{formatDistanceToNow(row.getValue('lastOpenedAt'), { addSuffix: true })}</span>
          ),
        },
        {
          accessorKey: 'createdBy',
          header: 'Created By',
          cell: ({ row }: { row: Row<RecentlyViewedItemResponse> }) => (
            <span>
              {isDemoWorkspace
                ? row.original.createdBy === 'support@perceptinsight.com' || row.original.createdBy === userInfo?.email
                  ? row.original.createdBy
                  : '******@****.***'
                : row.original.createdBy}
            </span>
          ),
        },
        currentUserOnly && {
          id: 'reportDelete',
          cell: ({ row }: { row: Row<RecentlyViewedItemResponse> }) => (
            <Button variant="icon" onClick={() => showDeleteDialogBox(row.original.itemExternalId)}>
              <DeleteIcon />
            </Button>
          ),
        },
      ].filter(Boolean) as ColumnDef<RecentlyViewedItemResponse>[],
    [currentUserOnly],
  );

  return (
    <div className="w-full">
      <CreateReportPills />
      {getRecentlyViewedData.error ? (
        <ApiErrorView error={getRecentlyViewedData.error} retry={() => getRecentlyViewedData.mutate()} />
      ) : getRecentlyViewedData.isLoading && !isLengthyArray(reportsResponse) ? (
        <SpinLoader />
      ) : isLengthyArray(reportsResponse) || isLengthyArray(reportTypes) ? (
        <div className="pb-10">
          <div className="flex justify-between items-center px-3 py-5 border border-border rounded-t-xl sticky top-navbar bg-background z-10">
            <p className="font-semibold text-lg">Recently Viewed</p>
            <ListViewToggler />
          </div>
          {viewState === ListViewType.ListView ? (
            <VirtualTable
              columns={columns}
              data={reportsResponse ?? []}
              loading={getRecentlyViewedData.isLoading}
              onEndReached={loadMore}
              useWindowScroll
              roundedTable={false}
            />
          ) : (
            <ReportsGrid
              data={reportsResponse ?? []}
              loading={getRecentlyViewedData.isLoading}
              onEndReached={loadMore}
              useWindowScroll
            />
          )}
        </div>
      ) : (
        NoReportsPlaceholder
      )}
      {showDeleteDialog ? (
        <AlertDialog
          open={showDeleteDialog}
          onCancel={closeDeleteDialogBox}
          loading={deleteReportReq.isLoading}
          onContinue={handleDelete}
        />
      ) : null}
    </div>
  );
}
