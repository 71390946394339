// NOTE: Radix primittives don't support multi select right now. Using 'react-select' for now. Some other inspiration using 'cmdk' package and other radix primitives
// https://github.com/mxkaske/mxkaske.dev/blob/main/components/craft/fancy-multi-select.tsx
// https://craft.mxkaske.dev/post/fancy-multi-select

import { matchSorter } from 'match-sorter';
import React, { useEffect, useState } from 'react';
import ReactSelect, {
  components as ReactSelectComponents,
  createFilter,
  MultiValueProps,
  NoticeProps,
  OptionProps,
} from 'react-select';

import { SelectCheckIcon } from '../icons/ReportIcons';
import { Tooltip } from '../tooltip';
import { cn, HoverableClassNames } from '../utils';
import { SelectClassNames, SelectOptionsType } from './types';

const highlightInputMatch = (item: string | undefined, keyword: string) => {
  if (!item || !keyword) return item;
  const lowerCasedInputValue = keyword.toLowerCase();
  const hitIndex = item.toLocaleLowerCase().indexOf(lowerCasedInputValue);
  if (hitIndex === -1) return item;
  const before = item.slice(0, hitIndex);
  const match = item.slice(hitIndex, hitIndex + keyword.length);
  const after = item.slice(hitIndex + keyword.length);
  return (
    <span>
      {before}
      <b>{match}</b>
      {after}
    </span>
  );
};

function CustomOption({ innerProps, data, isSelected, isDisabled, isFocused, ...props }: OptionProps) {
  // eslint-disable-next-line no-unused-vars
  const { onMouseMove, onMouseOver, ...rest } = innerProps; // check: https://www.botsplash.com/post/optimize-your-react-select-component-to-smoothly-render-10k-data

  const { inputValue } = props.selectProps;

  return (
    <div
      {...rest}
      className={cn(
        'cursor-default select-none flex w-full justify-between items-center rounded py-1.5 px-2 mb-1 bg-transparent hover:bg-gray-100 hover:dark:bg-foreground-secondary focus:bg-gray-100 focus:dark:bg-foreground-secondary ',
        isSelected ? 'bg-gray-200 dark:bg-foreground-secondary' : '',
        isFocused ? 'bg-gray-200 dark:bg-foreground-secondary' : '',
        isDisabled ? 'cursor-not-allowed' : '',
      )}
    >
      <Tooltip content={(data as any).tooltip ?? (data as any).label}>
        <div className="inline-flex items-center space-x-1 w-full">
          {(data as any).icon ? (data as any).icon : null}
          <p className={`truncate text-sm outline-none ${isDisabled ? 'opacity-50' : ''}`}>
            {highlightInputMatch((data as any).label, inputValue)}
          </p>
        </div>
      </Tooltip>
      {isSelected ? <SelectCheckIcon /> : null}
    </div>
  );
}

function NoOptionsMessage({ ...props }: NoticeProps) {
  return <p className="text-xs font-normal text-center">No options found</p>;
}

function CustomMultiValue({ index, getValue, data, ...props }: MultiValueProps) {
  const maxToShow = 5;
  const values = getValue();
  const overflowCount = Math.max(values.length - maxToShow, 0);

  if (index < maxToShow) {
    return (
      <Tooltip
        content={<p className="text-foreground">{((values[index] as any)?.label as string) ?? ''}</p>}
        side="bottom"
        contentClassname="bg-background border border max-h-[180px] max-w-[520px] overflow-y-scroll"
      >
        <span>
          <ReactSelectComponents.MultiValue index={index} data={data} getValue={getValue} {...props} />
        </span>
      </Tooltip>
    );
  }
  if (index === maxToShow) {
    const overflowValues = values
      .slice(maxToShow)
      .map((value) => (value as SelectOptionsType).label)
      .join(', ');

    return (
      <Tooltip
        content={<p className="text-foreground">{overflowValues}</p>}
        side="bottom"
        contentClassname="bg-background border border max-h-[180px] max-w-[520px] overflow-y-scroll"
      >
        <span>
          <ReactSelectComponents.MultiValue
            index={index}
            getValue={getValue}
            data={{ isFixed: true }}
            {...props}
          >{`+${overflowCount} more`}</ReactSelectComponents.MultiValue>
        </span>
      </Tooltip>
    );
  }
  return null;
}

export const Select = React.forwardRef<
  React.ElementRef<typeof ReactSelect>,
  React.ComponentPropsWithoutRef<typeof ReactSelect> & { selectClassNames?: SelectClassNames }
>(
  (
    {
      isMulti,
      autoFocus,
      value,
      isLoading,
      options,
      menuIsOpen,
      placeholder,
      selectClassNames,
      onChange,
      onBlur,
      components = {},
      ...props
    },
    ref,
  ) => {
    const [selectOptions, setSelectOptions] = useState(options);

    useEffect(() => {
      setSelectOptions(options);
    }, [options]);

    return (
      <ReactSelect
        ref={ref}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        autoFocus={autoFocus}
        openMenuOnFocus
        hideSelectedOptions={false}
        value={value}
        isLoading={isLoading}
        onChange={onChange}
        options={selectOptions}
        onInputChange={(inputValue) => {
          setSelectOptions(matchSorter(options || [], inputValue, { keys: ['label'] }));
        }}
        unstyled
        onBlur={onBlur}
        menuIsOpen={menuIsOpen}
        placeholder={placeholder}
        components={{
          Option: CustomOption,
          NoOptionsMessage,
          MultiValue: CustomMultiValue,
          ...components,
        }}
        filterOption={createFilter({ ignoreAccents: false })}
        styles={{
          // Fixes the overlapping problem of the component
          menu: (provided) => ({ ...provided, zIndex: 20 }),
        }}
        classNames={{
          control: (_state) =>
            cn('border border-border rounded-xl px-2', HoverableClassNames, selectClassNames?.control),
          menu: (_state) =>
            cn(
              'border border-border bg-popover text-popover-foreground shadow-md p-2 mt-2 rounded z-20',
              selectClassNames?.menu,
            ),
          // option: (state) =>
          // cn(
          //   'cursor-default truncate select-none items-center rounded py-1.5 px-2 mb-1 text-sm outline-none bg-transparent hover:bg-primary-light hover:dark:bg-foreground-secondary focus:bg-primary-light focus:dark:bg-foreground-secondary',
          //   state.isSelected ? 'bg-primary-light dark:bg-foreground-secondary' : '',
          // ),
          singleValue: (_state) => cn('text-sm font-normal', selectClassNames?.singleValue),
          multiValue: (_state) => 'bg-white border border-border dark:border-0 rounded-xl px-2 py-0.5 select-none',
          multiValueLabel: (_state) => 'text-xs text-black font-normal bg-transparent',
          multiValueRemove: (_state) => 'text-black',
          placeholder: (_state) =>
            cn(
              'text-sm font-normal text-foreground-secondary dark:text-foreground',
              selectClassNames?.placeholder ?? '',
            ),
          indicatorsContainer: (_state) => cn('opacity-70', selectClassNames?.indicatorsContainer),
          dropdownIndicator: (_state) => cn('', selectClassNames?.dropdownIndicator),
          loadingMessage: (_state) => 'text-sm font-semibold text-foreground-secondary',
          container: (state) =>
            cn(state.isDisabled ? 'opacity-50 !cursor-not-allowed' : '', selectClassNames?.container),
          menuList: (_state) => cn('overflow-x-hidden', selectClassNames?.menuList),
        }}
        {...props}
      />
    );
  },
);

Select.displayName = 'Select';
