import { ApiError } from '@src/client/lib/api/errors';
import { CSSProperties, useEffect, useState } from 'react';

import { Button } from '../../ui-library/button';
import { cn } from '../../ui-library/utils';

interface Props {
  error: ApiError | Error | unknown;
  className?: string;
  imageClassName?: string;
  style?: CSSProperties;
  retry?: () => void;
}

const getErrorImage = (error: ApiError | Error | unknown): string => {
  if (error instanceof ApiError) {
    switch (error.status) {
      case 403:
        return '/images/v2/errors/403.svg';
      case 404:
        return '/images/v2/errors/404.svg';
      case 429:
        return '/images/v2/errors/429.svg';
      case 422:
        return '/images/v2/errors/403.svg';
      case 500:
        return '/images/v2/errors/500.svg';
      case 503:
        return '/images/v2/errors/503.svg';
      case 504:
        return '/images/v2/errors/504.svg';
      default:
        return '/images/v2/errors/429.svg';
    }
  }
  return '/images/v2/errors/429.svg';
};

export default function ApiErrorView({ error, retry, style = {}, className = '', imageClassName = '' }: Props) {
  const [details, setDetails] = useState<string>();
  const [showDetails, setShowDetails] = useState<boolean>(false);

  useEffect(() => {
    const handleDetailsRetrieval = async () => {
      const res = await (error as ApiError).details;
      setDetails(res);
    };
    if (error instanceof ApiError) {
      handleDetailsRetrieval();
    }
    if ((error as Error)?.cause) {
      setDetails((error as Error)?.cause as string);
    }
  }, [error]);
  return (
    <div className={cn('flex flex-col  m-auto', className)} style={{ ...style }}>
      <img src={getErrorImage(error)} alt="Error" className={cn('mb-6 mx-auto w-[200px]', imageClassName)} />
      {retry ? <Button onClick={retry}>Retry</Button> : null}
      {error !== null && (error as ApiError).status === 422 ? (
        <div className="grid grid-cols-1 text-center">
          <p className=" text-base text-gray-400">Report contains private data</p>
          <p className="text-xs text-gray-500">
            This content is currently inaccessible due to containing private data. Please reach out to your
            administrator to request access.
          </p>
        </div>
      ) : details ? (
        <>
          <Button variant="link" className="text-danger opacity-80" onClick={() => setShowDetails(!showDetails)}>
            {showDetails ? 'Hide' : 'Show'} Details
          </Button>
          {showDetails ? (
            <p className="break-all text-center text-xs text-danger opacity-70">
              {details.replace('[QUERY CONSTRUCTOR] [500]', '')}
            </p>
          ) : null}
        </>
      ) : null}
    </div>
  );
}
