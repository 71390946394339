import { getValidFilters } from '@src/client/components/filters-and-selectors/global-property-filter/utils';
import Layout from '@src/client/components/layout';
import { getFilterFormObjFromReportResponse, getFormattedGlobalFilters } from '@src/client/helpers/reports/dataUtils';
import { LogicalOperatorType } from '@src/client/helpers/reports/types';
import { useNavigationLinkWithWorkspace } from '@src/client/hooks';
import { ErrorTags } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';
import { isLengthyArray } from '@src/client/lib/utils';
import { ScreenNames } from '@src/client/routes/data';
import { Button } from '@src/client/ui-library/button';
import { UsersIcon } from '@src/client/ui-library/icons/NavbarIcons';
import { Input } from '@src/client/ui-library/input';
import { Label } from '@src/client/ui-library/label';
import { SpinLoader } from '@src/client/ui-library/loaders';
import { useToast } from '@src/client/ui-library/toast/use-toast';
import { isEmpty } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import ApiErrorView from '../../components/api-error-view';
import { isPrivateDimensionError } from '../../components/private-dimension-error';
import { SelectOptionsType } from '../../ui-library/select';
import { createCohort, getCohortItem, updateCohort, uploadCohortCsv } from './api/mutations';
import FilterSelectionArea from './components/FilterSelectionArea';
import SelectUsersArea from './components/SelectUsersArea';
import StaticCohortViewArea from './components/StaticCohortViewArea';
import UploadCsvArea from './components/UploadCsvArea';
import {
  cohortMetaState,
  cohortPrivateDimensionErrorState,
  csvFileOnErrorState,
  csvFileState,
  groupFiltersState,
  groupOperatorState,
  selectedUsersState,
} from './recoil/atoms';
import {
  CohortType,
  CreateCohortRequest,
  EventTypeRow,
  GroupFilter,
  ItemScope,
  PropertyType,
  SupportedMathAggregation,
} from './types';
import { writeCohortUpdateTimestampToLocalstorage } from './utils/cohortUtils';

export enum CohortCreationMethod {
  UploadCSV = 'UploadCSV',
  UserSelection = 'UserSelection',
  FilterSelection = 'FilterSelection',
}

export default function CreateCohortScreen({
  creationMethod = CohortCreationMethod.FilterSelection,
}: {
  creationMethod?: CohortCreationMethod;
}) {
  const [cohortMeta, setCohortMeta] = useRecoilState(cohortMetaState);
  const [groupFilters, setGroupFilters] = useRecoilState(groupFiltersState);
  const [groupOperator, setGroupOperator] = useRecoilState(groupOperatorState);
  const [selectedUsers, setSelectedUsers] = useRecoilState(selectedUsersState);
  const [csvFile, setCsvFile] = useRecoilState(csvFileState);
  const [onError, setOnError] = useRecoilState(csvFileOnErrorState);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [existingCohortLoading, setExistingCohortLoading] = useState<boolean>(false);
  const [cohortPrivateDimensionError, setCohortPvtDimnErr] = useRecoilState(cohortPrivateDimensionErrorState);
  const { itemExtId } = useParams<{ itemExtId: string }>();
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();
  const [isEditMode, setIsEditMode] = useState(false);
  const [cohortCreationMethod, setCohortCreationMethod] = useState(creationMethod);
  const { toast } = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const cohortItemRequest = useMutation(getCohortItem, {
    onSuccess: (response) => {
      // eslint-disable-next-line no-console
      if (response.params) {
        setCohortMeta({
          cohortTitle: response.name,
          cohortDescription: response.description,
          count: response.params.count,
          firstTen: response.params.firstTen,
        });
        if (response.cohortType === CohortType.STATIC) setCohortCreationMethod(CohortCreationMethod.UserSelection);
        if (response.params.groups) {
          const temp: GroupFilter[] = response.params.groups.map(
            (grp: { operator: LogicalOperatorType | SelectOptionsType; filter: any }) => ({
              operator: typeof grp.operator === 'object' ? grp.operator.value : grp.operator, // change to response.params.operator after backend is ready for multi group operator
              filters: grp.filter.map((event: EventTypeRow) => ({
                operator: grp.operator,
                event: {
                  ...event,
                  event_filters: !isEmpty(event.event_filters)
                    ? getFilterFormObjFromReportResponse(event.event_filters as any)
                    : [], // api response will have object
                },
                key: Math.random(),
              })),
              key: Math.random(),
            }),
          );
          setGroupFilters(temp);
        }
      }
      setGroupOperator(response.params.operator); // remove this state after backend is ready for multi group operator
      setExistingCohortLoading(false);
    },
    onError: (error: Error, variable) => {
      // eslint-disable-next-line no-console
      if (isPrivateDimensionError(error)) {
        setCohortPvtDimnErr(error);
      } else {
        toast({
          variant: 'danger',
          title: 'Unable to fetch cohort',
          description: 'There was an error while fetching cohort',
        });
        Tracker.trackError(error, ErrorTags.COHORT_ITEM_FETCH_ERROR, {
          itemExternalId: variable,
        });
      }
      setExistingCohortLoading(false);
    },
    retry: 0,
  });

  const fetchCohortItem = useCallback(() => {
    if (itemExtId) {
      setExistingCohortLoading(true);
      cohortItemRequest.mutateAsync({ itemExtId });
    }
  }, [itemExtId]); // eslint-disable-line react-hooks/exhaustive-deps

  const createCohortRequest = useMutation(createCohort, {
    onSuccess: (res) => {
      toast({
        variant: 'success',
        title:
          cohortCreationMethod === CohortCreationMethod.UserSelection
            ? 'Cohort creation queued'
            : 'Cohort successfully created',
        description: '',
      });
      writeCohortUpdateTimestampToLocalstorage(res.itemExternalId);
      setSubmitLoading(false);
      navigate(getLinkWithWorkspace(`cohort`), { replace: true });
    },
    onError: (error: Error, variables) => {
      // eslint-disable-next-line no-console
      toast({
        variant: 'danger',
        title: 'Unable to create cohort',
        description: 'There was an error while creating cohort',
      });
      setSubmitLoading(false);
      Tracker.trackError(error, ErrorTags.COHORT_CREATE_ERROR, {
        itemName: variables?.itemName,
      });
    },
    retry: 0,
  });

  const modifyCohortRequest = useMutation(updateCohort, {
    onSuccess: async () => {
      toast({
        variant: 'success',
        title: 'Cohort successfully updated',
        description: '',
      });
      writeCohortUpdateTimestampToLocalstorage(itemExtId!);
      await queryClient.invalidateQueries(['UserCount', itemExtId]); // invalidate event fetch data
      navigate(getLinkWithWorkspace(`cohort`), { replace: false });
    },
    onError: (error: Error, variables) => {
      toast({
        variant: 'danger',
        title: 'Failed to update cohort',
        description: 'There was an error while updating cohort',
      });
      Tracker.trackError(error, ErrorTags.COHORT_UPDATE_ERROR, {
        itemExternalId: variables?.itemExternalId,
        itemName: variables?.itemName,
      });
    },
  });

  const uploadCsvRequest = useMutation(uploadCohortCsv, {
    onSuccess: () => {
      toast({
        variant: 'success',
        title: 'Upload csv successful',
        description: '',
      });
      setSubmitLoading(false);
      setCsvFile(undefined);
      navigate(getLinkWithWorkspace(`cohort`), { replace: true });
    },
    onError: (error: Error, variables) => {
      // eslint-disable-next-line no-console
      toast({
        variant: 'danger',
        title: 'Unable to upload csv file',
        description: 'There was an error while uploading the csv file',
      });
      setSubmitLoading(false);
      setOnError(true);
      Tracker.trackError(error, ErrorTags.COHORT_FILE_UPLOAD_ERROR);
    },
    retry: 0,
  });

  const validateStates = useCallback(() => {
    const allowedCharsRegex = /^[a-zA-Z0-9 _-]+$/;
    if (cohortMeta.cohortTitle === '' || !allowedCharsRegex.test(cohortMeta.cohortTitle)) {
      toast({
        variant: 'danger',
        title: 'Error',
        description: 'Invalid input. Cohort title should not be empty and should not contain special characters.',
      });
      return false;
    }
    if (cohortMeta.cohortDescription?.length > 0 && !allowedCharsRegex.test(cohortMeta.cohortDescription)) {
      toast({
        variant: 'danger',
        title: 'Error',
        description: 'Invalid input. Cohort description should not contain special characters.',
      });
      return false;
    }

    switch (cohortCreationMethod) {
      case CohortCreationMethod.FilterSelection:
        if (groupFilters.length === 0) {
          toast({
            variant: 'danger',
            title: 'Error',
            description: 'At least one group is necessary for creating a cohort',
          });
          return false;
        }
        for (let i = 0; i < groupFilters.length; i += 1) {
          const grp = groupFilters[i];
          if (grp.filters.length === 0) {
            toast({ variant: 'danger', title: 'Error', description: 'At least one filter is necessary in each group' });
            return false;
          }
          if (!grp.operator) {
            toast({ variant: 'danger', title: 'Error', description: `Logical operator not set for group ${i + 1}` });
            return false;
          }
          for (let j = 0; j < grp.filters.length; j += 1) {
            if (!grp.filters[j].operator) {
              toast({
                variant: 'danger',
                title: 'Error',
                description: `Logical operator not set for filter ${j + 1} in group ${i + 1}`,
              });

              return false;
            }

            const grpEvent = grp.filters[j].event;

            if (!grpEvent) {
              toast({
                variant: 'danger',
                title: 'Error',
                description: `Filter value not set for filter ${j + 1} in group ${i + 1}`,
              });
              return false;
            }
            if (
              grpEvent.type === PropertyType.EVENT &&
              grpEvent.aggregation?.math === SupportedMathAggregation.distinctCount &&
              !grpEvent.aggregation?.property
            ) {
              toast({
                variant: 'danger',
                title: 'Error',
                description: `Filter property not set for distinct count for filter ${j + 1} in group ${i + 1}`,
              });
              return false;
            }
          }
        }
        return true;
      case CohortCreationMethod.UserSelection:
        if (selectedUsers.length === 0) {
          toast({
            variant: 'danger',
            title: 'Error',
            description: 'At least one user selection is necessary for creating a cohort',
          });
          return false;
        }
        return true;

      case CohortCreationMethod.UploadCSV:
        if (csvFile === undefined) {
          toast({
            variant: 'danger',
            title: 'Error',
            description: 'No file selected for cohort creation',
          });
          return false;
        }
        return true;
      default:
        toast({
          variant: 'danger',
          title: 'Error',
          description: 'Invalid cohort properties',
        });
        return false;
    }
  }, [
    cohortMeta.cohortTitle,
    cohortMeta.cohortDescription,
    groupFilters,
    selectedUsers,
    toast,
    csvFile,
    cohortCreationMethod,
  ]);

  const onOk = useCallback(async () => {
    if (validateStates()) {
      setSubmitLoading(true);
      if (cohortCreationMethod === CohortCreationMethod.UploadCSV) {
        if (csvFile !== undefined) {
          const formData = new FormData();
          formData.append('file', csvFile);
          formData.append('item-name', cohortMeta.cohortTitle);
          formData.append('item-description', cohortMeta.cohortDescription);
          uploadCsvRequest.mutate(formData);
        }
      } else {
        const createCohortObj: CreateCohortRequest = {
          itemName: cohortMeta.cohortTitle,
          itemDescription: cohortMeta.cohortDescription,
          itemScope: ItemScope.PUBLIC,
          cohortType:
            cohortCreationMethod === CohortCreationMethod.FilterSelection ? CohortType.DYNAMIC : CohortType.STATIC,
          userIdValues: selectedUsers.map((userId) => userId.value).join(','),
          params: {
            groups: groupFilters.map((grp) => ({
              operator: grp.operator,
              filter: grp.filters.map((filter) => {
                const inlineEventFilters = getValidFilters(filter.event.event_filters ?? []);
                return {
                  ...filter.event,
                  event_filters: isLengthyArray(inlineEventFilters)
                    ? getFormattedGlobalFilters(inlineEventFilters)
                    : undefined,
                };
              }),
            })),
            operator: groupOperator,
          },
        };
        if (itemExtId) {
          await modifyCohortRequest.mutateAsync({
            ...createCohortObj,
            itemExternalId: itemExtId,
            itemScope: ItemScope.PUBLIC,
          });
        } else {
          createCohortRequest.mutate(createCohortObj);
        }
      }
    }
  }, [
    validateStates,
    cohortMeta.cohortTitle,
    cohortMeta.cohortDescription,
    groupFilters,
    groupOperator,
    itemExtId,
    modifyCohortRequest,
    createCohortRequest,
    cohortCreationMethod,
    csvFile,
    selectedUsers,
    uploadCsvRequest,
  ]);

  useEffect(() => {
    if (!itemExtId) {
      setCohortMeta({
        cohortTitle: '',
        cohortDescription: '',
      });
      setSelectedUsers([]);
      setGroupFilters([]);
      setCsvFile(undefined);
      setOnError(false);
    }
  }, [itemExtId, setCohortMeta, setGroupFilters, setSelectedUsers, setOnError, setCsvFile]);

  useEffect(() => {
    if (itemExtId) {
      if (itemExtId === 'create') {
        setIsEditMode(false);
        return;
      }
      setIsEditMode(true);
      fetchCohortItem.call(itemExtId);
    }
  }, [itemExtId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout screenName={ScreenNames.CreateCohort} contentStyle={{ paddingBottom: '80px' }}>
      <div className="flex flex-col w-full justify-between items-center px-10 py-5 sticky top-navbar z-10 bg-background border-b border-border">
        <div className="flex items-center justify-between w-full space-x-12">
          <div className="flex items-center">
            <div className="border border-border p-2 mr-2 rounded-lg bg-grayBlue-100 dark:bg-grayBlue-600">
              <UsersIcon />
            </div>
            <p className="font-bold text-lg ml-1">Cohort</p>
          </div>
          {!cohortPrivateDimensionError && (
            <div className="flex-1">
              <div className="flex items-center justify-end space-x-3">
                <Link to={getLinkWithWorkspace(`cohort`)}>
                  <Button variant="outline" className="h-10 min-w-200">
                    <span className="mx-2">Cancel</span>
                  </Button>
                </Link>
                {isEditMode && isLengthyArray(groupFilters) && (
                  <Button
                    className="h-10 min-w-200"
                    onClick={onOk}
                    loading={submitLoading}
                    disabled={cohortMeta.cohortTitle.length === 0}
                  >
                    <span className="mx-2"> Apply</span>
                  </Button>
                )}
                {!isEditMode && (
                  <Button
                    className="h-10 min-w-200"
                    onClick={onOk}
                    loading={submitLoading}
                    disabled={cohortMeta.cohortTitle.length === 0}
                  >
                    <span className="mx-2"> Create</span>
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {!cohortPrivateDimensionError && (
        <div className="px-10 py-5">
          {existingCohortLoading ? (
            <SpinLoader />
          ) : (
            <div className="flex flex-col gap-y-4">
              <div>
                <Label>Cohort Title</Label>
                <div className="h-px" />
                <Input
                  placeholder="Enter Cohort Name (required)"
                  value={cohortMeta.cohortTitle}
                  onChange={(a) => {
                    setCohortMeta({
                      ...cohortMeta,
                      cohortTitle: a.target.value,
                    });
                  }}
                />
              </div>
              <div>
                <Label>Cohort Description</Label>
                <div className="h-px" />
                <Input
                  placeholder="Enter Cohort Description (optional)"
                  value={cohortMeta.cohortDescription}
                  onChange={(a) => {
                    setCohortMeta({
                      ...cohortMeta,
                      cohortDescription: a.target.value,
                    });
                  }}
                />
              </div>
              {cohortCreationMethod === CohortCreationMethod.FilterSelection && <FilterSelectionArea />}
              {cohortCreationMethod === CohortCreationMethod.UploadCSV && <UploadCsvArea isLoading={submitLoading} />}
              {cohortCreationMethod === CohortCreationMethod.UserSelection &&
                (isEditMode ? <StaticCohortViewArea /> : <SelectUsersArea />)}
            </div>
          )}
        </div>
      )}
      {(cohortPrivateDimensionError as Error | null) && (
        <div className="mt-10">
          <ApiErrorView error={cohortPrivateDimensionError} />
        </div>
      )}
    </Layout>
  );
}
