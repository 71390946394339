import { activeWorkspaceState, userInfoState } from '@src/client/recoil/atoms';
import { DependencyList, EffectCallback, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { globalPageLoaderState } from '../components/global-page-loader';
import { DEMO_WORKSPACE_ID, ELEVATED_ACCESS_ROLES } from '../components/site-navbar/constants';
import { ErrorTags } from '../lib/analytics/events';
import Tracker from '../lib/analytics/tracker';
import { updateLastActiveWorkspaceId } from '../lib/api/mutations/common';
import { Workspace } from '../lib/api/types/response';
import { saveActiveWorkspaceDetailsInLocalStorage } from '../lib/utils';
import { useToast } from '../ui-library/toast/use-toast';

export function usePrevious<T>(value: T): T | undefined {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref: any = useRef<T>();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export function useNavigationLinkWithWorkspace() {
  const activeWorkspace = useRecoilValue(activeWorkspaceState);

  const getLinkWithWorkspace = (path: string) => `/${activeWorkspace?.id}/${path}`;

  return {
    getLinkWithWorkspace,
  };
}

export function useOnScreen(ref: RefObject<HTMLElement>) {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [isOnScreen, setIsOnScreen] = useState(false);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) => setIsOnScreen(entry.isIntersecting));
  }, []);

  useEffect(() => {
    if (ref?.current) observerRef?.current?.observe(ref.current);

    return () => {
      observerRef?.current?.disconnect();
    };
  }, [ref]);

  return isOnScreen;
}

export function useEffectAfterMount(cb: EffectCallback, dependencies: DependencyList | undefined) {
  const mounted = useRef(true);

  useEffect(() => {
    if (!mounted.current) {
      return cb();
    }
    mounted.current = false;

    return () => {};
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
}

export function useIsFlowDisabled() {
  const userInfo = useRecoilValue(userInfoState);
  return userInfo?.activeWorkspaceId?.includes('udaan');
}

export function useIsDateRangeMoreThanWeekDisabled() {
  return false;
}

export function useIsExperimentEnabled() {
  const userInfo = useRecoilValue(userInfoState);
  const workspaceIds = ['perceptpixel', 'percepttest', 'percept-webapp', 'saasboost'];

  return workspaceIds.some((id) => userInfo?.activeWorkspaceId?.includes(id));
}

export function useIsHeatMapEnabled() {
  const userInfo = useRecoilValue(userInfoState);
  const workspaceIds = ['perceptpixel', 'percepttest', 'percept-webapp'];

  return workspaceIds.some((id) => userInfo?.activeWorkspaceId?.includes(id));
}

export function useIsCampaignReminderEnabled() {
  const userInfo = useRecoilValue(userInfoState);
  return userInfo?.activeWorkspaceId?.includes('perceptpixel');
}

export function useIsCampaignFirstTimeFilterEnabled() {
  const userInfo = useRecoilValue(userInfoState);
  return userInfo?.activeWorkspaceId?.includes('perceptpixel');
}

export function useIsPerceptPixelWorkspace() {
  const userInfo = useRecoilValue(userInfoState);
  return userInfo?.activeWorkspaceId?.includes('perceptpixel');
}

export function useIsSupermoneyWorkspace() {
  const userInfo = useRecoilValue(userInfoState);
  return userInfo?.activeWorkspaceId?.includes('supermoney');
}

export function useIsSupermoneyLendingWorkspace() {
  const userInfo = useRecoilValue(userInfoState);
  return userInfo?.activeWorkspaceId?.includes('supermoneylending');
}

export function useIsDemoWorkspace() {
  const userInfo = useRecoilValue(userInfoState);
  const pathArr = window.location.href.split('/');
  return pathArr[2].includes('demo') || userInfo?.activeWorkspaceId?.includes(DEMO_WORKSPACE_ID);
}

export function useIsWorkspaceCreationEnabled() {
  const userInfo = useRecoilValue(userInfoState);
  const pathArr = window.location.href.split('/');
  const isDemoWorkspace = pathArr[2].includes('demo') || userInfo?.activeWorkspaceId?.includes('demo-ecommerce-app');
  const isElevatedAccess = userInfo && ELEVATED_ACCESS_ROLES.includes(userInfo.roleName);
  const isEligibleWs = ['perceptpixel', 'percepttest', 'percept-webapp'].some((id) =>
    userInfo?.activeWorkspaceId?.includes(id),
  );
  return isDemoWorkspace || (isElevatedAccess && isEligibleWs);
}

interface ResizableElementDimensions {
  width: number;
  height: number;
}

export function useResizableElement(elementRef: React.RefObject<HTMLElement>): ResizableElementDimensions {
  const [dimensions, setDimensions] = useState<ResizableElementDimensions>({
    width: 0,
    height: 0,
  });

  const handleResize = useCallback(() => {
    if (elementRef.current) {
      const { clientWidth, clientHeight } = elementRef.current;
      setDimensions({
        width: clientWidth,
        height: clientHeight,
      });
    }
  }, [elementRef]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize, elementRef]);

  return dimensions;
}

export function useWorkspaceSelector(): { (ws: Workspace, redirect?: string): Promise<boolean> } {
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const setShowGlobalPageLoader = useSetRecoilState(globalPageLoaderState);
  const setActiveWorkspace = useSetRecoilState(activeWorkspaceState);
  const updateActiveWorkspaceRequest = useMutation(updateLastActiveWorkspaceId);

  const handleWorkspaceSelection = async (ws: Workspace, redirect?: string): Promise<boolean> => {
    setShowGlobalPageLoader(true);
    try {
      await updateActiveWorkspaceRequest.mutateAsync({ lastActiveWorkspaceId: ws.id });
      setActiveWorkspace(ws);
      saveActiveWorkspaceDetailsInLocalStorage(ws.id, ws.tenantId);
      Tracker.updateWorkspaceAndTenant();
      await queryClient.invalidateQueries();
      setShowGlobalPageLoader(false);
      window.location.replace(redirect || `/${ws.id}/home`);
      return true;
    } catch (error: any) {
      setShowGlobalPageLoader(false);
      toast({
        variant: 'danger',
        title: 'Workspace switching failed',
      });
      Tracker.trackError(error, ErrorTags.ACTIVE_WORKSPACE_UPDATE_ERROR, {
        workspaceToUpdate: ws.id,
        changingFrom: 'User Settings',
      });
      return false;
    }
  };
  return handleWorkspaceSelection;
}
