import { googleLogout } from '@react-oauth/google';
import { useIsDemoWorkspace, useNavigationLinkWithWorkspace } from '@src/client/hooks';
import { EventNames } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';
import { Cookie } from '@src/client/lib/api/constants';
import { getUserInfoOld, updateLoginTenant } from '@src/client/lib/api/queries/common';
import { OauthProvider } from '@src/client/lib/api/types/request';
import { msalLogout } from '@src/client/lib/msal-utils';
import { clearActiveWorkspaceDetailsFromLocalStorage } from '@src/client/lib/utils';
import { SdkMenuIcon } from '@src/client/modules/sdk-integration/components/Icons';
import { userInfoState } from '@src/client/recoil/atoms';
import { Avatar, AvatarFallback, AvatarImage } from '@src/client/ui-library/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@src/client/ui-library/dropdown';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { DataGovernanceIcon, SingleUserIcon } from '../../ui-library/icons/DashboardIcons';
import { EngageIcon, LogoutIcon, SettingsIcon } from '../../ui-library/icons/NavbarIcons';
import { EventDropdownIcon } from '../../ui-library/icons/ReportIcons';
import { globalPageLoaderState } from '../global-page-loader';
import { ELEVATED_ACCESS_ROLES } from './constants';
import ThemeToggler from './ThemeToggler';

const USE_OLD_AUTH_SERVICE = true;

const elevatedAccessItems = [
  {
    label: 'Users and Teams',
    link: 'settings/manage-users',
    eventName: EventNames.MANAGE_USERS_CLICKED,
    icon: <SingleUserIcon />,
  },
  {
    label: 'Data Governance',
    link: 'settings/data-governance',
    eventName: EventNames.DATA_GOVERNANCE_CLICKED,
    icon: <DataGovernanceIcon />,
  },
  {
    label: 'Engage Settings',
    link: 'engage-settings',
    eventName: EventNames.ENGAGE_SETTINGS_CLICKED,
    icon: <EngageIcon />,
  },
];

function DropdownItem({
  label,
  link,
  eventName,
  icon,
}: {
  label: string;
  link: string;
  eventName: EventNames;
  icon: ReactNode;
}) {
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();
  return (
    <div>
      <DropdownMenuLabel className="flex items-center justify-between mb-2 hover:bg-gray-50 dark:hover:bg-foreground-secondary">
        <Link
          to={getLinkWithWorkspace(link)}
          onClick={() => Tracker.trackEvent(eventName)}
          className="inline-flex items-center"
        >
          <div className="px-2 w-8">{icon}</div>
          <div className="ml-4 text-sm font-semibold">{label}</div>
        </Link>
      </DropdownMenuLabel>
      <DropdownMenuSeparator className="px-2 my-2" />
    </div>
  );
}

export default function SettingsMenu() {
  const [userName, setUserName] = useState('');
  const [iconUrl, setIconUrl] = useState('');
  const [tenantId] = useState('');
  const [userData, setUserData] = useRecoilState(userInfoState);
  const [cookies, , removeCookie] = useCookies([
    Cookie.id_token,
    Cookie.refresh_token,
    Cookie.auth_type,
    Cookie.id_token_expiry,
  ]);
  const queryClient = useQueryClient();
  const [darkMode] = useState(false);
  const setShowGlobalPageLoader = useSetRecoilState(globalPageLoaderState);
  const isElevatedAccess = userData && ELEVATED_ACCESS_ROLES.includes(userData.roleName);
  const { mutate: updateTenantMutation } = useMutation(updateLoginTenant);
  const { data: userInfo, status } = useQuery(['userInfo', {}], getUserInfoOld, {
    enabled: sessionStorage.getItem('first_time_user') !== 'true',
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
  });
  const isDemoWorkspace = useIsDemoWorkspace();

  useEffect(() => {
    if (userInfo !== undefined) {
      setUserName(`${userInfo.firstName} ${userInfo.lastName}` || '');
      setIconUrl(userInfo.profileUrl || '');
      setUserData(userInfo);
      Tracker.setUserInfo(userInfo);
    }
  }, [userInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSignOut = useCallback(async () => {
    setShowGlobalPageLoader(true);
    if (!USE_OLD_AUTH_SERVICE) {
      updateTenantMutation(tenantId);
    }
    Tracker.logout();
    clearActiveWorkspaceDetailsFromLocalStorage();
    removeCookie(Cookie.id_token, {
      path: '/',
    });
    removeCookie(Cookie.refresh_token, {
      path: '/',
    });
    removeCookie(Cookie.id_token_expiry, {
      path: '/',
    });
    removeCookie(Cookie.auth_type, {
      path: '/',
    });
    await queryClient.invalidateQueries();
    if (cookies[Cookie.auth_type] === OauthProvider.google) {
      googleLogout();
    }
    if (cookies[Cookie.auth_type] === OauthProvider.microsoft) {
      await msalLogout();
    }
    setShowGlobalPageLoader(false);
    window.location.href = '/login';
  }, [tenantId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (status === 'error') {
      onSignOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove('light', 'dark');
    const theme = darkMode ? 'dark' : 'light';
    root.classList.add(theme);
  }, [darkMode]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="bg-transparent mx-3 outline-none focus:outline-none hover:bg-neutral-dark dark:hover:bg-neutral-dark rounded-full p-1 data-[state=open]:text-gray-300 data-[state=open]:bg-neutral-dark dark:data-[state=open]:text-gray-300 dark:data-[state=open]:bg-neutral-dark">
        <SettingsIcon className="pl-0.5 pt-[1px]" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel className="flex items-center">
          <Avatar>
            <AvatarImage src={iconUrl} />
            <AvatarFallback>{userName?.[0] ?? ''}</AvatarFallback>
          </Avatar>
          <div className="ml-2 text-sm font-semibold">{userName}</div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator className="px-2 my-2" />
        <DropdownItem
          label="Custom Events"
          link="custom-events/list"
          eventName={EventNames.CUSTOM_EVENTS_PAGE_CLICKED}
          icon={<EventDropdownIcon />}
        />
        {!isDemoWorkspace && (
          <DropdownItem
            label="Sdk Integrations"
            link="sdk-integrations"
            eventName={EventNames.SDK_INTEGRATIONS_CLICKED}
            icon={<SdkMenuIcon />}
          />
        )}
        {isElevatedAccess && (
          <section>
            {elevatedAccessItems.map(({ label, link, eventName, icon }) => (
              <DropdownItem key={link} label={label} link={link} eventName={eventName} icon={icon} />
            ))}
          </section>
        )}
        <DropdownMenuLabel className="flex items-center justify-between mb-2">
          <p className="text-xs font-semibold">Dark Mode</p>
          <ThemeToggler />
        </DropdownMenuLabel>
        <DropdownMenuLabel
          onClick={onSignOut}
          className="flex items-center justify-between cursor-pointer rounded-md bg-transparent hover:bg-gray-50 dark:hover:bg-foreground-secondary"
        >
          <p className="text-xs font-semibold">Logout</p>
          <LogoutIcon />
        </DropdownMenuLabel>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
