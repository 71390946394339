import { googleLogout } from '@react-oauth/google';
import { Cookie } from '@src/client/lib/api/constants';
import { signup, validateAndLogin } from '@src/client/lib/api/mutations/common';
import AsyncCreatableSelect from '@src/client/ui-library/select/AsyncCreatableSelect';
import { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import LoginBg from '../../components/login-bg';
import { ErrorTags } from '../../lib/analytics/events';
import Tracker from '../../lib/analytics/tracker';
import { OauthProvider } from '../../lib/api/types/request';
import countries from '../../lib/country.json';
import { clearActiveWorkspaceDetailsFromLocalStorage, convertToLabelValue } from '../../lib/utils';
import { ScreenNames } from '../../routes/data';
import { Button } from '../../ui-library/button';
import { Input } from '../../ui-library/input';
import { Select } from '../../ui-library/select/Select';
import { SelectOptionsType } from '../../ui-library/select/types';
import { getIsWorkspaceAvailable } from './api/mutations';
import { organisations } from './organisations';

function Welcome() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [cookies, , removeCookie] = useCookies([Cookie.refresh_token, Cookie.id_token, Cookie.auth_type]);
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const name = searchParams.get('name') ? searchParams.get('name') : email;
  const profileUrl = searchParams.get('profileUrl') ? searchParams.get('profileUrl') : null;
  const [selectedCountry, setSelectedCountry] = useState<SelectOptionsType>({
    value: `+${countries[0].value}`,
    label: `${countries[0].label}`,
  });
  const [organisation, setOrganisation] = useState<SelectOptionsType>();
  const [contact, setContact] = useState<string>('');
  const [showError, setshowError] = useState<boolean>(false);
  const handleCountrySelection = (country: any) => {
    setSelectedCountry(country as SelectOptionsType);
  };
  const [workspaceAvailable, setWorkspaceAvailable] = useState<boolean>(false);

  const checkIsWorkspaceAvailable = useMutation(getIsWorkspaceAvailable, {
    onSuccess: (res) => {
      setWorkspaceAvailable(res);
    },
    onError: (err: Error) => {
      Tracker.trackError(err, ErrorTags.FETCH_IS_WORKSPACES_AVAILABLE);
    },
  });

  const isNotValid = () =>
    (organisation && organisation.value.trim() === '') || !selectedCountry || !/^\d{10}$/.test(contact);

  useEffect(() => {
    checkIsWorkspaceAvailable.mutateAsync();
    Tracker.setCurrentScreenName(ScreenNames.WelcomeScreen);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const authorize = () => {
    validateAndLogin()
      .then(async (_r) => {
        await queryClient.invalidateQueries('userInfo');
        navigate(workspaceAvailable ? `/create-workspace` : '/demo-ecommerce-app/home');
      })
      .catch((e) => {
        Tracker.trackError(e, ErrorTags.AUTHORIZE_ERROR);
        Tracker.logout();
        if (cookies[Cookie.auth_type] === OauthProvider.google) {
          googleLogout();
        }
        clearActiveWorkspaceDetailsFromLocalStorage();
        removeCookie(Cookie.id_token, {
          path: '/',
        });
        removeCookie(Cookie.refresh_token, {
          path: '/',
        });
        removeCookie(Cookie.auth_type, {
          path: '/',
        });
        queryClient.invalidateQueries();
      });
  };

  const autoSignup = () => {
    if (isNotValid()) {
      setshowError(true);
      return;
    }
    setshowError(false);
    signup(name!, email!, profileUrl, organisation!.value, `${selectedCountry?.value}${contact}`).then(
      async (_r: any) => {
        sessionStorage.removeItem('first_time_user');
        authorize();
      },
    );
  };

  const loadOrganisations = (inputValue: string, _cb: any): Promise<SelectOptionsType[]> | void =>
    new Promise((resolve) => {
      const options = organisations
        .filter(
          (item) =>
            item.toLowerCase().includes(inputValue.toLowerCase()) && item.toLowerCase() !== inputValue.toLowerCase(),
        )
        .map((item) => convertToLabelValue(item) as SelectOptionsType);
      resolve(options);
    });

  const formatCreateLabel = useCallback((inputValue: string) => <p>{inputValue}</p>, []);

  const handleChange = (value: string) => {
    if (value.trim().length > 0 && /^[a-zA-Z\s]*$/.test(value)) {
      setOrganisation(convertToLabelValue(value) as SelectOptionsType);
    }
  };

  return (
    <LoginBg>
      <div className="flex items-center justify-center flex-col p-4">
        <div style={{ width: '550px' }} className="px-4 py-12 bg-white rounded-2xl">
          <p className="font-extrabold text-black text-h6 text-center">👋🏻&nbsp;&nbsp;Lets help you get started!</p>
          <div className="flex flex-col px-24 items-start m-auto">
            <span className="mt-8 text-sm">Organisation</span>
            <AsyncCreatableSelect
              placeholder="Who do you work for?"
              onChange={(val) => handleChange((val as SelectOptionsType).value)}
              onBlur={(e) => handleChange(e.target.value)}
              formatCreateLabel={formatCreateLabel}
              value={organisation}
              loadOptions={loadOrganisations}
              defaultOptions
              selectClassNames={{
                container: 'w-full mt-1',
                placeholder: 'text-gray-500',
              }}
            />
            {showError && organisation && organisation.value.trim() === '' && (
              <span className=" mt-1 text-danger text-xs font-bold">Required*</span>
            )}
            <span className="mt-6 text-sm">Contact Number</span>
            <div className="mt-1 flex w-full">
              <Select
                options={countries.map((country) => ({
                  value: `+${country.value}`,
                  label: `+${country.value} (${country.label})`,
                }))}
                value={selectedCountry ? { label: selectedCountry.value, value: selectedCountry.value } : undefined}
                onChange={handleCountrySelection}
                selectClassNames={{
                  container: 'w-[100px]',
                  menu: '!w-[300px]',
                  control: 'rounded-r-none border-r-0 h-9',
                  placeholder: 'text-gray-500 text-xs',
                }}
              />
              <Input
                className="flex-grow rounded-l-none border-l-0 placeholder:text-gray-500 h-[38px] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                placeholder="How can we reach you?"
                type="number"
                value={contact}
                onChange={(e) => setContact(e.target.value)}
              />
            </div>
            {showError && (!selectedCountry || !/^\d{10}$/.test(contact)) && (
              <span className="mt-1 text-danger text-xs font-bold">Phone number invalid</span>
            )}
            <Button className="mt-6 w-full" onClick={autoSignup}>
              Continue →
            </Button>
          </div>
        </div>
      </div>
    </LoginBg>
  );
}

export default Welcome;
