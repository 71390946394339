/* eslint-disable react/require-default-props */
import SiteNavbar from '@src/client/components/site-navbar';
import { EventNames } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';
import { ScreenNames } from '@src/client/routes/data';
import { ThemeProvider, ThemeStorageKey } from '@src/client/ui-library/theme-provider';
import { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Toaster } from '../../ui-library/toast/toaster';

interface LayoutProps extends PropsWithChildren {
  screenName: ScreenNames;
  contentStyle?: React.CSSProperties;
}

export default function Layout({ children, screenName, contentStyle = {} }: LayoutProps) {
  const location = useLocation();

  useEffect(() => {
    Tracker.setCurrentScreenName(screenName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location && location.pathname !== '/') {
      Tracker.trackEvent(
        EventNames.PAGE_VIEW,
        {},
        {
          pathName: location.pathname,
        },
      );
    }
  }, [location]);

  return (
    <ThemeProvider defaultTheme="light" storageKey={ThemeStorageKey}>
      <SiteNavbar />
      <main className="pt-[60px] bg-background text-foreground min-h-layout-v2 box-content" style={{ ...contentStyle }}>
        {children}
      </main>
      <Toaster />
    </ThemeProvider>
  );
}
