import { FILTER_OPERATORS } from '@src/client/components/filters-and-selectors/global-property-filter/constants';
import { getUpdatedFilters } from '@src/client/components/filters-and-selectors/global-property-filter/utils';
import { useAllEvents, useAllProperties } from '@src/client/helpers/reports/hooks';
import { Filter, FilterPropertyType, LogicalOperatorType } from '@src/client/helpers/reports/types';
import { useIsSupermoneyLendingWorkspace, useIsSupermoneyWorkspace } from '@src/client/hooks';
import { PluralSupportedTimeUnits } from '@src/client/lib/api/types/response';
import { convertDayJsDateTimeToIST, isLengthyArray } from '@src/client/lib/utils';
import { Button } from '@src/client/ui-library/button';
import { DeleteIcon } from '@src/client/ui-library/icons/DashboardIcons';
import { Input } from '@src/client/ui-library/input';
import { Select, SelectOptionsType } from '@src/client/ui-library/select';
import { useToast } from '@src/client/ui-library/toast/use-toast';
import dayjs from 'dayjs';
import { ReactNode, useEffect, useState } from 'react';

import { AGGREGATION_MATH_OPTIONS, EVENT_FILTER_TYPE_OPTIONS } from '../constants';
import {
  EventTypeRow,
  PropertyType,
  SupportedFilterConditions,
  SupportedMathAggregation,
  TimeWindowTypes,
} from '../types';
import EventFilterRowDropdownMenu, { EventFilterRowDropdownKeys } from './EventFilterRowDropdownMenu';
import EventFilterTimeWindowSelector from './EventFilterTimeWindowSelector';
import InlineEventFiltersRow from './InlineEventFiltersRow';

interface EventFilterRowProps {
  value?: EventTypeRow; // eslint-disable-line react/require-default-props
  onChange: (value: any) => void;
  rowIndex: number;
  remove: (index: number) => void;
  onGroupOperatorChange: (value: LogicalOperatorType) => void;
  groupOperatorSelector: ReactNode;
  enableDidNotFilter: boolean;
  isFirstEventRow: boolean;
}

function EventFilterRow({
  value,
  onChange,
  rowIndex,
  remove,
  onGroupOperatorChange,
  groupOperatorSelector,
  enableDidNotFilter,
  isFirstEventRow,
}: EventFilterRowProps) {
  const [didOperator, setDidOperator] = useState<'did' | 'did_not'>(value && value.dnd ? 'did_not' : 'did');
  const [selectedEvent, setSelectedEvent] = useState<string>(value!.property);
  const [aggregationMath, setAggregationMath] = useState<SupportedMathAggregation>(
    value && value.aggregation && value.aggregation.math ? value.aggregation.math : SupportedMathAggregation.total,
  );
  const isSuperMoneyWs = useIsSupermoneyWorkspace();
  const isSuperMoneylendingWs = useIsSupermoneyLendingWorkspace();

  const { loading, properties } = useAllProperties();
  const { isLoading, dimensionsList } = useAllEvents('event_name', undefined);
  const { toast } = useToast();

  useEffect(() => {
    if (value && value.dnd) {
      setDidOperator('did_not');
    } else {
      setDidOperator('did');
    }
  }, [value]);

  function getInitialTimeWindowType(type: string): TimeWindowTypes {
    if (!type || type.toLowerCase() === 'last') {
      return TimeWindowTypes.Last;
    }
    if (type.toLowerCase() === 'since') {
      return TimeWindowTypes.Since;
    }
    if (type.toLowerCase() === 'fixed') {
      return TimeWindowTypes.Fixed;
    }
    return TimeWindowTypes.Last;
  }
  const [timeWindowType, setTimeWindowType] = useState<TimeWindowTypes>(
    value && value.time_window ? getInitialTimeWindowType(value?.time_window?.type) : TimeWindowTypes.Last,
  );
  const [firstTimeFilter, setFirstTimeFilter] = useState<boolean>(
    value && value.first_time_filter ? value.first_time_filter : false,
  );
  function getInitialPropertyValue(val: string | undefined): SelectOptionsType | undefined {
    if (typeof val === 'string') {
      return {
        label: val,
        value: val,
      };
    }
    return undefined;
  }
  const [selectedProperty, setSelectedProperty] = useState<SelectOptionsType | undefined>(
    getInitialPropertyValue(value?.aggregation?.property),
  );
  const [filterBy, setFilterBy] = useState<SupportedFilterConditions>(
    value && value.filter ? value.filter.condition : SupportedFilterConditions.equal,
  );
  function getInitialFilterValue(val: number | string[] | undefined): number {
    if (typeof val === 'number') {
      return val;
    }
    // default value of 1 is returned in case of string[] value or any other invalid input.
    return 1;
  }

  const [filterValue, setFilterValue] = useState<number>(getInitialFilterValue(value?.filter?.value));

  const [timeWindowValue, setTimeWindowValue] = useState<number>(
    value && value.time_window && value.time_window.value ? value.time_window.value : 30,
  );
  const [timeWindowUnit, setTimeWindowUnit] = useState<
    PluralSupportedTimeUnits.DAYS | PluralSupportedTimeUnits.MONTHS | PluralSupportedTimeUnits.WEEKS
  >(value && value.time_window && value.time_window.unit ? value.time_window.unit : PluralSupportedTimeUnits.DAYS);
  const [timeWindowFromTimestamp, setTimeWindowFromTimestamp] = useState<dayjs.Dayjs>(
    value && value.time_window && value.time_window.from_timestamp
      ? dayjs(value.time_window.from_timestamp)
      : dayjs().subtract(7, 'day'),
  );
  const [timeWindowToTimestamp, setTimeWindowToTimestamp] = useState<dayjs.Dayjs>(
    value && value.time_window && value.time_window.to_timestamp ? dayjs(value.time_window.to_timestamp) : dayjs(),
  );

  const [eventFilters, setEventFilters] = useState<Filter[]>(value?.event_filters ?? []);

  useEffect(() => {
    let timeWindow;
    if (timeWindowType === TimeWindowTypes.Last) {
      timeWindow = {
        type: 'last',
        value: timeWindowValue,
        unit: timeWindowUnit,
      };
    } else if (timeWindowType === TimeWindowTypes.Fixed) {
      timeWindow = {
        type: 'fixed',
        from_timestamp: convertDayJsDateTimeToIST(timeWindowFromTimestamp),
        to_timestamp: convertDayJsDateTimeToIST(timeWindowToTimestamp, true),
      };
    } else if (timeWindowType === TimeWindowTypes.Since) {
      timeWindow = {
        type: 'since',
        from_timestamp: convertDayJsDateTimeToIST(timeWindowFromTimestamp),
        // from_timestamp: timeWindowFromTimestamp.add(5, 'hours').add(30, 'minutes').format('YYYY-MM-DD'),
      };
    } else {
      toast({
        variant: 'danger',
        title: 'Cohorts not working',
        description: 'Kindly contact Percept team for support.',
      });
      timeWindow = {
        type: 'unrecognized',
      };
    }

    const tempValue: EventTypeRow = {
      type: PropertyType.EVENT,
      dnd: didOperator === 'did_not' ? true : undefined,
      property: selectedEvent,
      aggregation: {
        math: aggregationMath,
        propertyType: 'event_property',
        property: selectedProperty?.value,
        property_name: selectedProperty?.value ?? '*',
      },
      filter: {
        condition: filterBy,
        value: filterValue,
      },
      time_window: timeWindow,
      first_time_filter: firstTimeFilter,
      event_filters: eventFilters,
    };
    onChange(tempValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    didOperator,
    selectedEvent,
    aggregationMath,
    filterBy,
    filterValue,
    timeWindowValue,
    timeWindowUnit,
    firstTimeFilter,
    timeWindowType,
    timeWindowFromTimestamp,
    timeWindowToTimestamp,
    selectedProperty,
    eventFilters,
  ]);

  const addNewInslineEventFilter = () => {
    const filterToAdd: Filter = {
      key: eventFilters.length + 1,
      property: '',
      resource_type: FilterPropertyType.EVENT,
      operator: FILTER_OPERATORS[0],
      values: [],
    };

    setEventFilters([...eventFilters, { ...filterToAdd }]);
  };

  const handleDropdownMenuClick = (itemKey: EventFilterRowDropdownKeys) => {
    if (itemKey === EventFilterRowDropdownKeys.FILTER) addNewInslineEventFilter();
    if (itemKey === EventFilterRowDropdownKeys.FIRST_TIME_FILTER) setFirstTimeFilter((prevValue) => !prevValue);
    if (itemKey === EventFilterRowDropdownKeys.REMOVE) remove(rowIndex);
  };

  const onTimeWindowTabChange = (key: string) => {
    setTimeWindowType(key as TimeWindowTypes);
  };

  const selectTimeWindowValue = (val: number) => {
    setTimeWindowValue(val);
  };

  const selectTimeWindowUnit = (
    val: PluralSupportedTimeUnits.DAYS | PluralSupportedTimeUnits.MONTHS | PluralSupportedTimeUnits.WEEKS,
  ) => {
    setTimeWindowUnit(val);
  };

  const selectTimeWindowFromTimestamp = (val: dayjs.Dayjs) => setTimeWindowFromTimestamp(val);
  const selectTimeWindowToTimestamp = (val: dayjs.Dayjs) => setTimeWindowToTimestamp(val);

  const handleEventInlineFilterChange = (newFilter: Filter, filterIdx: number) =>
    setEventFilters(getUpdatedFilters(eventFilters, newFilter, filterIdx));

  const removeInlineEventFilter = (filterIndex: number) =>
    setEventFilters(eventFilters.filter((_, i) => i !== filterIndex).map((f, i) => ({ ...f, key: i + 1 })));

  const handleDidOperatorChange = (val: unknown) => {
    const didValue = (val as SelectOptionsType).value as 'did' | 'did_not';
    if (didValue === 'did_not') onGroupOperatorChange('and');
    setDidOperator(didValue);
  };

  const getDidOptions = [
    { label: 'did', value: 'did' },
    ...(isSuperMoneylendingWs
      ? []
      : [
          {
            label: 'did not',
            value: 'did_not',
            isDisabled: didOperator === 'did' && (!enableDidNotFilter || isFirstEventRow),
            tooltip:
              didOperator === 'did' && !enableDidNotFilter
                ? `Cannot make all filters "did not" inside a group`
                : isFirstEventRow
                ? `Can't make first filter "did not"`
                : undefined,
          },
        ]),
  ];

  return (
    <>
      <div className="flex flex-row gap-x-2 gap-y-2 flex-wrap items-center">
        <div className="flex justify-center items-center">
          {rowIndex === 0 ? <span className="w-20 text-center">who</span> : groupOperatorSelector}
        </div>
        <div>
          <Select
            className="min-w-100"
            value={getDidOptions.find((item) => (item as SelectOptionsType).value === didOperator)}
            options={getDidOptions}
            onChange={handleDidOperatorChange}
          />
        </div>
        <div>
          <Select
            isLoading={isLoading}
            placeholder="Select Event..."
            className="min-w-200"
            value={dimensionsList.find((item) => item.label === selectedEvent)}
            onChange={(val) => setSelectedEvent((val as SelectOptionsType).label)}
            options={dimensionsList}
          />
        </div>
        <div>
          <Select
            className="min-w-150"
            value={
              aggregationMath === SupportedMathAggregation.total
                ? { label: 'total', value: 'total' }
                : { label: 'distinct count', value: 'distinct count' }
            }
            onChange={(val) => setAggregationMath((val as SelectOptionsType).value as SupportedMathAggregation)}
            options={AGGREGATION_MATH_OPTIONS}
          />
        </div>
        {aggregationMath === SupportedMathAggregation.distinctCount && (
          <div>
            <Select
              className="min-w-200"
              isLoading={loading}
              value={selectedProperty}
              onChange={(val) => setSelectedProperty(val as SelectOptionsType)}
              options={properties}
              placeholder="Select a property..."
            />
          </div>
        )}
        <div>
          <Select
            className="min-w-200"
            options={EVENT_FILTER_TYPE_OPTIONS}
            value={EVENT_FILTER_TYPE_OPTIONS.find((item) => item.value === filterBy)}
            onChange={(val) => setFilterBy((val as SelectOptionsType).value as SupportedFilterConditions)}
            placeholder="Filter by..."
          />
        </div>
        <div>
          <Input
            id="condition_value"
            type="number"
            value={filterValue}
            onChange={(val) => (val.target.value === null ? 1 : setFilterValue(Number(val.target.value)))}
            className="w-20 h-10"
          />
        </div>
        <div>
          <EventFilterTimeWindowSelector
            timeWindowUnit={timeWindowUnit}
            timeWindowValue={timeWindowValue}
            timeWindowType={timeWindowType}
            onTimeWindowTabChange={onTimeWindowTabChange}
            timeWindowToTimestamp={timeWindowToTimestamp}
            timeWindowFromTimestamp={timeWindowFromTimestamp}
            selectTimeWindowValue={selectTimeWindowValue}
            selectTimeWindowUnit={selectTimeWindowUnit}
            selectTimeWindowFromTimestamp={selectTimeWindowFromTimestamp}
            selectTimeWindowToTimestamp={selectTimeWindowToTimestamp}
          />
        </div>
        <EventFilterRowDropdownMenu firstTimeFilter={firstTimeFilter} onClick={handleDropdownMenuClick} />
        <Button variant="icon" onClick={() => remove(rowIndex)}>
          <DeleteIcon />
        </Button>
      </div>
      {isLengthyArray(eventFilters) || firstTimeFilter ? (
        <div className="ml-20 border-l border-l-border pl-2 space-y-1">
          {isLengthyArray(eventFilters) ? (
            <div className="space-y-1">
              {eventFilters.map((evtFilter, idx) => (
                <InlineEventFiltersRow
                  key={`${evtFilter.key}-${evtFilter.property}`}
                  filter={evtFilter}
                  filterIndex={idx}
                  removeFilter={removeInlineEventFilter}
                  onChange={handleEventInlineFilterChange}
                />
              ))}
            </div>
          ) : null}
          {firstTimeFilter && (
            <div className="flex items-center">
              For the
              <Input defaultValue="First Time Ever" className="w-36 mx-2" disabled style={{ cursor: 'default' }} />
              <Button variant="icon" onClick={() => setFirstTimeFilter(false)}>
                <DeleteIcon />
              </Button>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
}

export default EventFilterRow;
