import Percept from '@perceptinsight/percept-js';
import CreatedByFilter from '@src/client/components/filters-and-selectors/created-by-filter';
import { createdByFilterState } from '@src/client/components/filters-and-selectors/created-by-filter/state';
import ReportTypeFilter from '@src/client/components/filters-and-selectors/report-type-filter';
import { activeReportTypeFiltersState } from '@src/client/components/filters-and-selectors/report-type-filter/state';
import { RightSidepanel } from '@src/client/components/sidepanel';
import { rightSidepanelOpenState } from '@src/client/components/sidepanel/state';
import { useIsDemoWorkspace } from '@src/client/hooks';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

export default function HomeRightPanel({ hideCreatedBy }: { hideCreatedBy: boolean }) {
  const isSidepanelOpen = useRecoilValue(rightSidepanelOpenState);
  const isDemoWorkspace = useIsDemoWorkspace();
  const [filterName, setFilterName] = useState('Filters');

  useEffect(() => {
    async function fetchExperiment() {
      try {
        const experiment = await Percept.getExperiment('FilterName');

        if (experiment?.variantName === 'filter_1') {
          setFilterName('Selectors');
        } else if (experiment?.variantName === 'control') {
          setFilterName('Filters');
        }
      } catch (error) {
        console.error('Error fetching experiment:', error);
      }
    }
    fetchExperiment();
  }, []);

  return (
    <RightSidepanel>
      {isSidepanelOpen ? (
        <>
          <p className="text-sm font-semibold mb-3">{filterName}</p>
          <div className="flex flex-col space-y-3">
            <ReportTypeFilter reportTypeFiltersState={activeReportTypeFiltersState} />
            {hideCreatedBy || isDemoWorkspace ? null : <CreatedByFilter createdByFilterState={createdByFilterState} />}
          </div>
        </>
      ) : null}
    </RightSidepanel>
  );
}
