import * as PopoverPrimitive from '@radix-ui/react-popover';
import * as React from 'react';

import { cn, HoverableClassNamesWithOnState } from '../utils';

const Popover = PopoverPrimitive.Root;

const PopoverClose = PopoverPrimitive.Close;

const PopoverTrigger = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Trigger> & {
    inset?: boolean;
    noStyle?: boolean;
  }
>(({ className, inset, children, noStyle, ...props }, ref) => (
  <PopoverPrimitive.Trigger
    ref={ref}
    className={
      noStyle
        ? className
        : cn(
            'flex cursor-default select-none items-center rounded-lg border border-border px-2 py-1.5 text-sm bg-transparent font-semibold outline-none ',
            HoverableClassNamesWithOnState,
            className,
          )
    }
    {...props}
  >
    {children}
  </PopoverPrimitive.Trigger>
));
PopoverTrigger.displayName = 'PopoverTrigger';

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, align = 'center', sideOffset = 4, ...props }, ref) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={cn(
        'z-50 w-72 rounded-md border border-border bg-popover p-4 text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        className,
      )}
      {...props}
    />
  </PopoverPrimitive.Portal>
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export { Popover, PopoverTrigger, PopoverContent, PopoverClose };
