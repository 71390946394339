/* eslint-disable array-callback-return,  @typescript-eslint/naming-convention */
import { CustomCompareStateType } from '@src/client/components/filters-and-selectors/compare-selector/atoms';
import { FILTER_OPERATORS } from '@src/client/components/filters-and-selectors/global-property-filter/constants';
import { getValidFilters } from '@src/client/components/filters-and-selectors/global-property-filter/utils';
import { getDimensionMathAggregateOptions, getDimensionMathOptions } from '@src/client/helpers/reports/uiHelpers';
import { FetchEventRequestTimeProps } from '@src/client/lib/api/types/request';
import { DimensionType, GroupByObject, ReportType } from '@src/client/lib/api/types/response';
import { convertDayJsDateTimeToIST, getTypeOfFilterPropertyValues, isLengthyArray } from '@src/client/lib/utils';
import { checkFunnelStepHasEventCompare } from '@src/client/modules/funnels/dataUtils';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { QueryBuilderTimestampInfo } from '../workers/types';
import {
  AGGREGATE,
  CompareTimePeriodCustomSubTypes,
  CompareTimePeriodSubTypes,
  CompareType,
  DateRangeEnum,
  DimensionMathCountUsers,
  DimensionMathValues,
  EventPageDateRangeEnum,
  EventPageDateRangeMap,
  GranularityEnum,
  GRAPH_COLORS,
  MAX_GRAPH_COLORS,
  PERCENTILE,
  RetentionSubtypeEnum,
} from './constants';
import {
  AggregatedProperties,
  Breakdown,
  ChartData,
  Dimension,
  Filter,
  FormattedFiltersConfig,
  FormattedFiltersForAPI,
  Formula,
  FunnelStep,
  LineChartData,
} from './types';

dayjs.extend(utc);

export const addPastFlagToCompareString = (text: string, isPastData: boolean): string =>
  isPastData ? `${text} (Past)` : text;

export const replacePastFlagFromCompareString = (text: string): string => text.replace(' (Past)', '');

export const isPastCompareString = (text: string): boolean => !!text?.includes(' (Past');

export const isBreakdownApplied = (breakdowns: Breakdown[]): boolean => isLengthyArray(breakdowns);

export const isFormulaApplied = (formulas: Formula[]): boolean => isLengthyArray(formulas);

export const getBreakDownStrArray = (breakdowns: Breakdown[]) =>
  breakdowns.map((item) => (typeof item.property === 'string' ? item.property : `Cohorts [${item.property.length}]`));

export const getFilterFormObjFromReportResponse = (filters: FormattedFiltersForAPI): Filter[] => {
  const formattedValues: Filter[] = [];
  filters.config.forEach(
    ({ operator, property_name: property, property_values: values, property_map: map, resource_type }: any) => {
      if (FILTER_OPERATORS.indexOf(operator) === -1) return;
      formattedValues.push({
        key: formattedValues.length + 1,
        operator,
        property,
        values: Array.isArray(values) ? values : values.toString(),
        map,
        resource_type,
      });
    },
  );
  return formattedValues;
};

const getDimensionMathCountUserList = () => [
  DimensionMathCountUsers.DAU.toString(),
  DimensionMathCountUsers.WAU.toString(),
  DimensionMathCountUsers.MAU.toString(),
];

export function roundUpToNearest10(num: number) {
  return Math.ceil(num / 10) * 10;
}

const getDimensionMathAndAggregatedOnFormObj = (
  math: string,
  aggregateProperties: AggregatedProperties | undefined,
  uniqueOn: string | undefined,
): { math: string[]; aggregatedOn: string | null } => {
  if (math === AGGREGATE && aggregateProperties !== undefined) {
    const obj: { math: string[]; aggregatedOn: string | null } = { math: [], aggregatedOn: null };
    if (aggregateProperties.aggregate === PERCENTILE && aggregateProperties.percentile_value !== undefined) {
      const aggregatePropertyChild = getDimensionMathAggregateOptions().children?.find(
        (c) =>
          aggregateProperties.percentile_value &&
          parseFloat(c.value) === parseFloat(aggregateProperties.percentile_value.toString()),
      );
      const mathValue = aggregatePropertyChild === undefined ? '0.5' : aggregatePropertyChild.value;
      obj.math = [DimensionMathValues.AGGREGATE_PROPERTIES, mathValue];
    } else {
      obj.math = [DimensionMathValues.AGGREGATE_PROPERTIES, aggregateProperties.aggregate];
    }
    if (aggregateProperties.column_name !== undefined) {
      obj.aggregatedOn = aggregateProperties.column_name;
    }
    if (uniqueOn !== undefined) {
      obj.aggregatedOn = uniqueOn;
    }
    return obj;
  }
  if (getDimensionMathCountUserList().includes(math)) {
    return { math: [DimensionMathValues.COUNT_USERS, math], aggregatedOn: null };
  }
  return { math: [math], aggregatedOn: null };
};

export const getGlobalDimensionFormObjFromInsightResponse = (dimensions: Dimension[]): Dimension[] => {
  const formattedValues: any[] = [];
  dimensions.map((it: any) => {
    const { alias, math, aggregate_properties, name, filters, first_time_filter, dimension_label, resource_type } = it;
    const obj: any = {
      alias,
      name: name.split('|')[0],
      first_time_filter,
      resource_type: resource_type ?? name.split('|')?.[1], // NOTE: this is done as old saved report might not have resource_type property attached to it
    };
    const mathAndAggregatedOnObj = getDimensionMathAndAggregatedOnFormObj(math, aggregate_properties, it['unique-on']);
    if (mathAndAggregatedOnObj.aggregatedOn) {
      obj['aggregate-on'] = mathAndAggregatedOnObj.aggregatedOn;
    }
    if (mathAndAggregatedOnObj.math) {
      obj.math = mathAndAggregatedOnObj.math;
    }
    if (filters && filters.mapping) {
      obj['filter-mapping'] = filters.mapping;
    }
    if (dimension_label && dimension_label.length > 0) {
      obj.dimension_label = dimension_label;
    }
    obj.filter =
      filters && filters.config && filters.config.length > 0 ? getFilterFormObjFromReportResponse(filters) : [];
    formattedValues.push(obj);
  });
  return formattedValues;
};

export const getCustomDateRangeFormObjReportResponse = (timestampProps: any): [dayjs.Dayjs, dayjs.Dayjs] => {
  const from = timestampProps.from_timestamp;
  const to = timestampProps.to_timestamp;
  const customDateRange: [dayjs.Dayjs, dayjs.Dayjs] = [dayjs(from), dayjs(to)];
  return customDateRange;
};

export const getCompareFormObjFromReportResponse = (
  compare: any,
): { compareData: string[]; customCompareData: CustomCompareStateType | undefined } => {
  if (
    compare.sub_type === CompareTimePeriodCustomSubTypes.CUSTOM_END ||
    compare.sub_type === CompareTimePeriodCustomSubTypes.CUSTOM_START
  ) {
    return {
      compareData: [compare.type, CompareTimePeriodSubTypes.CUSTOM],
      customCompareData: {
        type: compare.sub_type,
        date: dayjs(compare.custom_compare_date),
      },
    };
  }
  return {
    compareData: [compare.type, compare.sub_type],
    customCompareData: undefined,
  };
};

export const getSinceDateRangeFormObjReportResponse = (timestampProps: any): [dayjs.Dayjs, dayjs.Dayjs] => {
  const from = timestampProps.from_timestamp;
  const to = dayjs();
  const sinceDateRange: [dayjs.Dayjs, dayjs.Dayjs] = [dayjs(from), to];
  return sinceDateRange;
};

export const getFormObjectForGroupBy = (group_by: GroupByObject[]): Breakdown[] => {
  const tempGroupBy: Breakdown[] = [];
  group_by.forEach((grpBy: GroupByObject) => {
    if (typeof grpBy.property === 'string') {
      const values = grpBy.property.split('|'); // NOTE: this is required as historical report have resource type in pipe
      const type = (values.length > 1 ? values[1] : grpBy.resource_type) as DimensionType;
      const breakdownObj: Breakdown = {
        property: values[0],
        type,
        group_by_type: grpBy.group_by_type,
        bucket_size: grpBy.bucket_size,
        include_null_users: grpBy.include_null_users,
      };
      tempGroupBy.push(breakdownObj);
    } else {
      tempGroupBy.push({
        property: grpBy.property,
        type: (grpBy.resource_type as DimensionType) ?? DimensionType.COHORT,
      });
    }
  });
  return tempGroupBy;
};

export const getFormattedGroupBy = (groupBy: Breakdown[]): GroupByObject[] => {
  const tempGroupBy: GroupByObject[] = [];
  groupBy.forEach((groupByObj) => {
    if (typeof groupByObj.property === 'string') {
      tempGroupBy.push({
        property: groupByObj.property,
        resource_type: groupByObj.type ?? DimensionType.PROPERTY,
        group_by_type: groupByObj.group_by_type,
        bucket_size: groupByObj.bucket_size,
        include_null_users: groupByObj.include_null_users,
      });
    } else {
      const arr = groupByObj.property;
      const tempCohortsList = arr.map((cohortString: string) => {
        const splitArr = cohortString.split('|');
        const cohortId = splitArr[0];
        return cohortId;
      });
      tempGroupBy.push({ property: tempCohortsList, resource_type: DimensionType.COHORT });
    }
  });
  return tempGroupBy;
};

export const getLogicalOperatorInWords = (symbol: string): string => {
  if (symbol === '&') {
    return 'and';
  }
  if (symbol === '||') {
    return 'or';
  }
  return '&';
};

export const getFilterMapping = (filters: any[]): string => {
  let filterMapping = '';
  filters.map((item) => {
    if (parseInt(item.key, 10) === 1) {
      filterMapping = filterMapping.concat(item.key.toString()).concat(' ');
    }
    if (parseInt(item.key, 10) > 1) {
      filterMapping = filterMapping
        .concat(getLogicalOperatorInWords(item.map))
        .concat(' ')
        .concat(item.key.toString().concat(' '));
    }
  });
  return filterMapping.trimEnd();
};

const getDimensionMath = (math: string[]) => {
  if (math && math.length === 1) {
    return math[0];
  }
  const selectedOption = getDimensionMathOptions().find((d) => d.value === math[0]);
  const isAggregatedProprty = selectedOption && selectedOption.value === DimensionMathValues.AGGREGATE_PROPERTIES;
  return isAggregatedProprty ? AGGREGATE : math[1];
};

const getAggregatedProperties = (mathAsPerForm: string[], aggregatedOn: string): AggregatedProperties | undefined => {
  const mathAsFormatted = getDimensionMath(mathAsPerForm);
  if (mathAsFormatted === AGGREGATE) {
    const aggregatedPropertyOptions = getDimensionMathOptions().find(
      (d) => d.value === DimensionMathValues.AGGREGATE_PROPERTIES,
    );
    if (aggregatedPropertyOptions !== undefined && aggregatedPropertyOptions.children) {
      const selectedOptionChild = aggregatedPropertyOptions.children.find((c) => c.value === mathAsPerForm[1]);
      const isPercentile = selectedOptionChild && parseFloat(selectedOptionChild.value);
      const aggregatedProperties: AggregatedProperties = {
        aggregate: isPercentile ? PERCENTILE : mathAsPerForm[1],
        column_name: aggregatedOn,
      };
      if (isPercentile) {
        aggregatedProperties.percentile_value = parseFloat(mathAsPerForm[1]);
      }
      return aggregatedProperties;
    }
    return undefined;
  }
  return undefined;
};

export const getFormattedDimensionFilters = (f: Filter): FormattedFiltersConfig | undefined => {
  const configObj: any = {};

  configObj.key = f.key;
  configObj.resource_type = f.resource_type;
  configObj.property_name = f.property;
  configObj.operator = f.operator;
  configObj.property_values = f.values;
  configObj.property_value_dt = getTypeOfFilterPropertyValues(f.values);
  configObj.property_map = f.map;
  return configObj;
};

export const getFormattedDimensions = (dimensions: Dimension[]) => {
  const formattedDimensions: Dimension[] = [];
  dimensions.map((item: any) => {
    const obj: any = {};
    if (item.alias) obj.alias = item.alias;
    if (item.math) obj.math = getDimensionMath(item.math);
    if (item.name) obj.name = item.name;
    if (item.resource_type) obj.resource_type = item.resource_type;
    if (item.first_time_filter) obj.first_time_filter = item.first_time_filter;
    if (item['aggregate-on']) {
      const aggregatedProperties = getAggregatedProperties(item.math, item['aggregate-on']);
      if (aggregatedProperties !== undefined) {
        obj.aggregate_properties = aggregatedProperties;
      }
    }
    if (item.dimension_label && item.dimension_label.length > 0) {
      obj.dimension_label = item.dimension_label;
    }
    const filterList = item.filter;
    if (filterList !== undefined && filterList.length > 0) {
      const filterObj: any = {};
      const filterConfig: any[] = [];
      const validFilters = getValidFilters(filterList);
      validFilters.forEach((f: Filter) => filterConfig.push(getFormattedDimensionFilters(f)));

      const filterMappingStr = getFilterMapping(validFilters);
      if (filterMappingStr !== '') filterObj.mapping = filterMappingStr;
      filterObj.config = filterConfig;
      obj.filters = filterObj;
    }
    formattedDimensions.push(obj);
  });

  return formattedDimensions;
};

export const getFormattedGlobalFilters = (filters: Filter[]): FormattedFiltersForAPI => {
  const globalFilters: any = {};
  const globalFiltersConfig: any[] = [];

  getValidFilters(filters).forEach((gf: any) => {
    const globalFiltersConfigObj: any = {};
    if (gf.resource_type) {
      if (gf.resource_type === DimensionType.PROPERTY) {
        globalFiltersConfigObj.resource_type = DimensionType.EVENT;
      } else {
        globalFiltersConfigObj.resource_type = gf.resource_type;
      }
    } else globalFiltersConfigObj.resource_type = 'event';
    // globalFiltersConfigObj['resource_type'] = 'event'

    globalFiltersConfigObj.key = gf.key;
    globalFiltersConfigObj.property_name = gf.property;
    globalFiltersConfigObj.operator = gf.operator;
    globalFiltersConfigObj.property_values = gf.values;
    globalFiltersConfigObj.property_value_dt = getTypeOfFilterPropertyValues(gf.values);
    globalFiltersConfigObj.property_map = gf.map;

    globalFiltersConfig.push(globalFiltersConfigObj);
  });

  globalFilters.config = globalFiltersConfig;
  const globalFilterMappingStr = getFilterMapping(getValidFilters(filters));
  if (globalFilterMappingStr !== '') globalFilters.mapping = globalFilterMappingStr;
  return globalFilters;
};

export const getFormattedChartProps = (viewMode: any, chartType: any) => {
  const chartProps: any = {};
  if (viewMode !== undefined) chartProps.view_mode = viewMode;
  if (chartType !== undefined) chartProps.chart_type = chartType;
  return chartProps;
};

const getDatesFromCustomRange = (value: [Dayjs, Dayjs] | []) => {
  const firstValue = typeof value[0] === 'string' ? dayjs(value[0]) : value[0];
  const secondValue = typeof value[1] === 'string' ? dayjs(value[1]) : value[1];
  const newObj: { from_timestamp?: string; to_timestamp?: string } = {
    from_timestamp: firstValue ? convertDayJsDateTimeToIST(firstValue) : '',
    to_timestamp: secondValue ? convertDayJsDateTimeToIST(secondValue, true) : '',
  };
  return newObj;
};

export const getFormattedTimestampProps = (
  granularity: any,
  dateRange: any,
  customDateRange?: [Dayjs, Dayjs] | [],
  sinceDateRange?: [Dayjs, Dayjs] | [],
) => {
  const timestampProps: any = {};
  if (granularity !== undefined) timestampProps.granularity = granularity;
  if (dateRange !== undefined) {
    let range: any = {};
    if (dateRange === DateRangeEnum.CUSTOM && customDateRange) {
      range = getDatesFromCustomRange(customDateRange);
      timestampProps.from_timestamp = range.from_timestamp;
      timestampProps.to_timestamp = range.to_timestamp;
    } else if (dateRange === DateRangeEnum.SINCE && sinceDateRange) {
      range = getDatesFromCustomRange(sinceDateRange);
      timestampProps.from_timestamp = range.from_timestamp;
      timestampProps.to_timestamp = range.to_timestamp;
    }
    timestampProps.date_range_type = dateRange;
  }
  return timestampProps;
};

export const formatDateRangeEnumToEventsDateRangeEnum = (dateRange: DateRangeEnum): EventPageDateRangeEnum =>
  EventPageDateRangeMap[dateRange];

export const getFormattedTimestampPropsForEventsApi = (
  dateRange: any,
  customDateRange?: [Dayjs, Dayjs] | [],
  sinceDateRange?: [Dayjs, Dayjs] | [],
): FetchEventRequestTimeProps => {
  const timestampProps: FetchEventRequestTimeProps = {
    type: dateRange === DateRangeEnum.CUSTOM || dateRange === DateRangeEnum.SINCE ? 'custom' : 'fixed',
  };
  if (dateRange !== undefined) {
    let range: any = {};
    if (dateRange === DateRangeEnum.CUSTOM && customDateRange) {
      range = getDatesFromCustomRange(customDateRange);
      timestampProps.start = range.from_timestamp;
      timestampProps.end = range.to_timestamp;
    } else if (dateRange === DateRangeEnum.SINCE && sinceDateRange) {
      range = getDatesFromCustomRange(sinceDateRange);
      timestampProps.start = range.from_timestamp;
      timestampProps.end = range.to_timestamp;
    } else {
      timestampProps.type = 'fixed';
      timestampProps.timeFilter = formatDateRangeEnumToEventsDateRangeEnum(dateRange);
    }
  }
  return timestampProps;
};

export const getFormattedCompare = (compare: any[] | undefined, customCompare: CustomCompareStateType | undefined) => {
  if (compare === undefined) return null;
  if (customCompare) {
    const compareObj = {
      type: CompareType.TIME_PERIOD,
      sub_type: customCompare.type,
      custom_compare_date: convertDayJsDateTimeToIST(
        customCompare.date,
        customCompare.type === CompareTimePeriodCustomSubTypes.CUSTOM_END,
      ),
    };
    return compareObj;
  }
  const type = compare[0];
  if (type === CompareType.TIME_PERIOD && compare.length === 2) {
    const subtype = compare[1];
    const compareObj = {
      type,
      sub_type: subtype,
    };
    return compareObj;
  }
  return null;
};

export const formatDate = (
  granularity: GranularityEnum,
  datetimeString: number | Date | string,
  reportType?: ReportType,
  reportSubtype?: RetentionSubtypeEnum,
): string => {
  if (reportType === ReportType.RETENTION && reportSubtype === RetentionSubtypeEnum.retention_table) {
    return datetimeString.toString();
  }
  const datetime = datetimeString instanceof Date ? dayjs(datetimeString) : dayjs.unix(Number(datetimeString));
  switch (granularity) {
    case GranularityEnum.MINUTE: {
      return datetime.format('h:mm A');
    }

    case GranularityEnum.HOUR: {
      return datetime.format('MMM D, hh:mm A');
    }

    case GranularityEnum.DAY: {
      return datetime.format('MMM D');
    }

    case GranularityEnum.WEEK: {
      const weekStart = datetime.startOf('week');
      const weekEnd = datetime.endOf('week');
      return `${weekStart.format('MMM D')} - ${weekEnd.format('MMM D')}`;
    }

    case GranularityEnum.MONTH: {
      return datetime.format('MMMM YYYY');
    }

    case GranularityEnum.QUARTER: {
      const quarterStartDate = dayjs(datetime)
        .startOf('month')
        .subtract(datetime.month() % 3, 'month');
      const quarterEndDate = dayjs(quarterStartDate).add(2, 'month').endOf('month');
      return `${quarterStartDate.format('MMM D')} - ${quarterEndDate.format('MMM D')}`;
    }

    default:
      throw new Error(`Invalid granularity: ${granularity}`);
  }
};

export const getGraphItemColor = (index: number): string => GRAPH_COLORS[index % GRAPH_COLORS.length];

export const getFunnelStepGraphItemColor = (index: number): string => GRAPH_COLORS[index % MAX_GRAPH_COLORS];

const getTextWidth = (text: string, font = '14px -apple-system') => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (context) {
    context.font = font;
    const metrics = context.measureText(text);
    return Math.round(metrics.width + 50);
  }
  return null;
};

export const getColumnWidth = (columnTitle: string): number => {
  const width = getTextWidth(columnTitle);
  if (width) return width;
  return 120;
};

export function numberToPeriod(num: number, granularity: GranularityEnum): string {
  const periodNames = {
    [GranularityEnum.DAY]: 'Day',
    [GranularityEnum.HOUR]: 'Hour',
    [GranularityEnum.MONTH]: 'Month',
    [GranularityEnum.WEEK]: 'Week',
    [GranularityEnum.MINUTE]: 'Minute',
    [GranularityEnum.QUARTER]: 'Quarter',
  };

  return num === 0 ? `< 1 ${periodNames[granularity]}` : `${periodNames[granularity]} ${num}`;
}

function extractNumber(str: string): number {
  // Check if the string contains "<"
  if (str.includes('<')) {
    return 0;
  }

  // Define a regular expression to match one or more digits
  const regex = /\d+/;
  // Use the match method to find the first occurrence of the pattern
  const match = str.match(regex);
  // If a match is found, return it as a number
  return match ? Number(match[0]) : 0;
}

function containsNumber(str: string): boolean {
  return /\d/.test(str);
}

export function getMaxNumberContainingElement(strList: string[]): string | undefined {
  let maxStr;
  let maxNum = 0;
  strList.forEach((strElement: string) => {
    if (containsNumber(strElement) && extractNumber(strElement) >= maxNum) {
      maxStr = strElement;
      maxNum = extractNumber(strElement);
    }
  });
  return maxStr;
}

export const addLineChartData = (
  breakdownColums: any,
  dataPoints: any,
  chartData: ChartData,
  isPastData: boolean,
  reportType?: ReportType,
  reportSubtype?: RetentionSubtypeEnum,
) => {
  const currentChartDataLength = chartData.length;
  Object.keys(dataPoints)
    .filter((value) => !value.includes('total'))
    .forEach((dp) => {
      const obj = {
        date:
          reportType === ReportType.RETENTION && reportSubtype === RetentionSubtypeEnum.retention_table
            ? extractNumber(dp)
            : dayjs(dp).unix(),
        value: dataPoints[dp],
      };
      const key = addPastFlagToCompareString(Object.values(breakdownColums).join('/'), isPastData);
      const existingIndex = (chartData as LineChartData[]).findIndex((item) => item.series === key);
      if (existingIndex === -1) {
        (chartData as LineChartData[]).push({
          series: key,
          key,
          color: getGraphItemColor(currentChartDataLength),
          data: [obj],
        });
      } else {
        (chartData as LineChartData[])[existingIndex].data.push(obj);
      }
    });
};

export function compareStepName(stepIndex: number, compareStepsCount: number): string {
  let result = `${stepIndex}A`;
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < compareStepsCount; i++) {
    result += ` or ${stepIndex}${String.fromCharCode(65 + i)}`;
  }
  return result;
}

export function compareStepColumnKey(stepIndex: number): string {
  return `$ored${stepIndex}`;
}

export const covertFunnelEventsCompareCombinations = (commaSeperatedCombinations: string[]): string[][] =>
  commaSeperatedCombinations.map((combination) => combination.split(','));

export const eventCompareStepNaming = (steps: FunnelStep[]): string[] =>
  steps.map((step, stepIndex) => {
    if (checkFunnelStepHasEventCompare(step)) {
      return compareStepName(stepIndex + 1, step.compare!.length);
    }
    return step.step_label!;
  });

export const getMappedHashCode = (userId: string, range: number) => {
  if (!userId || userId === '') {
    return 1;
  }
  return Math.abs(userId.split('').reduce((hash, char) => hash * 32 - hash + char.charCodeAt(0), 0) % range);
};

export const getStartDateFromQueryBuilderConfigForCustomCompare = (
  timestampData: QueryBuilderTimestampInfo,
): Dayjs | undefined => {
  if (timestampData.dateRange === DateRangeEnum.CUSTOM && isLengthyArray(timestampData.customDateRange))
    return timestampData.customDateRange![0];
  if (timestampData.dateRange === DateRangeEnum.SINCE && isLengthyArray(timestampData.customDateRange))
    return timestampData.customDateRange![0];
  if (timestampData.dateRange === DateRangeEnum.YESTERDAY) return dayjs().subtract(1, 'day');
  if (timestampData.dateRange === DateRangeEnum.SEVEN_DAYS) return dayjs().subtract(1, 'week');
  if (timestampData.dateRange === DateRangeEnum.FOURTEEN_DAYS) return dayjs().subtract(2, 'week');
  if (timestampData.dateRange === DateRangeEnum.THIRTY_DAYS) return dayjs().subtract(1, 'month');
  if (timestampData.dateRange === DateRangeEnum.SIX_MONTH) return dayjs().subtract(6, 'month');
  if (timestampData.dateRange === DateRangeEnum.TWELVE_MONTH) return dayjs().subtract(12, 'month');
  if (timestampData.dateRange === DateRangeEnum.TODAY) return dayjs();
  return undefined;
};

export const getEndDateFromQueryBuilderConfigForCustomCompare = (
  timestampData: QueryBuilderTimestampInfo,
): Dayjs | undefined => {
  if (timestampData.dateRange === DateRangeEnum.CUSTOM && isLengthyArray(timestampData.customDateRange))
    return timestampData.customDateRange![1];
  if (timestampData.dateRange === DateRangeEnum.SINCE && isLengthyArray(timestampData.customDateRange))
    return timestampData.customDateRange![1];
  if (timestampData.dateRange === DateRangeEnum.YESTERDAY) return dayjs().subtract(1, 'day');

  return dayjs();
};
