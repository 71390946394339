import { fetchEndpoint, UserGovService } from '@src/client/lib/api/utils';

import { PaginatedTransactions } from '../types';
import { RewardsEndpoints } from './endpoints';

export const getWalletBalance = async () => {
  const data: number = await fetchEndpoint(RewardsEndpoints.getBalance, {}, {}, {}, 'GET', UserGovService);
  return data;
};

export const getWalletLedger = async () => {
  const data: PaginatedTransactions = await fetchEndpoint(
    RewardsEndpoints.getledger,
    {},
    {},
    {},
    'GET',
    UserGovService,
  );
  return data;
};
