import { useTheme } from '@src/client/ui-library/theme-provider';
import { SVGProps } from 'react';

export const GotoLinkIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 6L14 2M14 2H10M14 2L8.66667 7.33333M6.66667 3.33333H5.2C4.0799 3.33333 3.51984 3.33333 3.09202 3.55132C2.71569 3.74307 2.40973 4.04903 2.21799 4.42535C2 4.85318 2 5.41323 2 6.53333V10.8C2 11.9201 2 12.4802 2.21799 12.908C2.40973 13.2843 2.71569 13.5903 3.09202 13.782C3.51984 14 4.0799 14 5.2 14H9.46667C10.5868 14 11.1468 14 11.5746 13.782C11.951 13.5903 12.2569 13.2843 12.4487 12.908C12.6667 12.4802 12.6667 11.9201 12.6667 10.8V9.33333"
      stroke="#155EEF"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export function SdkIcon(props: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2996_33329)">
        <path
          d="M21.5 43C21.3853 43 21.2671 42.9767 21.1596 42.932L5.93042 36.6611C5.59358 36.5232 5.375 36.1953 5.375 35.8334V23.2917C5.375 22.7972 5.77633 22.3959 6.27083 22.3959C6.76533 22.3959 7.16667 22.7954 7.16667 23.2917V35.2332L21.5 41.1349L35.8333 35.2332V23.2917C35.8333 22.7972 36.2347 22.3959 36.7292 22.3959C37.2237 22.3959 37.625 22.7954 37.625 23.2917V35.8334C37.625 36.1953 37.4064 36.5232 37.0696 36.6611L21.8404 42.932C21.7329 42.9767 21.6147 43 21.5 43Z"
          fill={theme === 'light' ? '#101323' : '#ffffff'}
        />
        <path
          d="M42.5788 19.8445L35.4121 15.3653C35.1487 15.1987 34.8137 15.1826 34.5378 15.3241L21.4998 21.8422L8.46364 15.3241C8.18414 15.1844 7.85089 15.1987 7.58751 15.3653L0.420844 19.8445C0.146719 20.0165 -0.0145312 20.3229 0.00159385 20.6454C0.0159272 20.9696 0.204052 21.2599 0.494302 21.405L14.8276 28.5717C14.9566 28.6344 15.0928 28.6666 15.229 28.6666C15.4261 28.6666 15.6213 28.6021 15.7826 28.4767L21.4998 23.9832L27.217 28.4767C27.3783 28.6021 27.5736 28.6666 27.7706 28.6666C27.9068 28.6666 28.043 28.6344 28.172 28.5717L42.5053 21.405C42.7956 21.2599 42.9855 20.9696 42.998 20.6454C43.0141 20.3229 42.8529 20.0165 42.5788 19.8445Z"
          fill={theme === 'light' ? '#4E5BA6' : '#ffffff'}
        />
        <path
          d="M12.9897 12.5416H9.85433C9.35983 12.5416 8.9585 12.1403 8.9585 11.6458C8.9585 11.1513 9.35983 10.75 9.85433 10.75H12.9897C13.7297 10.75 14.3335 10.1462 14.3335 9.40621C14.3335 8.66625 13.7297 8.06246 12.9897 8.06246H12.0939C10.365 8.06246 8.9585 6.656 8.9585 4.92704C8.9585 3.19808 10.365 1.79163 12.0939 1.79163H15.2293C15.7238 1.79163 16.1252 2.19117 16.1252 2.68746C16.1252 3.18375 15.7238 3.58329 15.2293 3.58329H12.0939C11.354 3.58329 10.7502 4.18708 10.7502 4.92704C10.7502 5.667 11.354 6.27079 12.0939 6.27079H12.9897C14.7187 6.27079 16.1252 7.67725 16.1252 9.40621C16.1252 11.1352 14.7187 12.5416 12.9897 12.5416Z"
          fill={theme === 'light' ? '#101323' : '#ffffff'}
        />
        <path
          d="M22.3957 12.5416H18.8123C18.3178 12.5416 17.9165 12.1403 17.9165 11.6458V2.68746C17.9165 2.19117 18.3178 1.79163 18.8123 1.79163H22.3957C23.8774 1.79163 25.0832 2.99742 25.0832 4.47913V9.85413C25.0832 11.3358 23.8774 12.5416 22.3957 12.5416ZM19.7082 10.75H22.3957C22.8884 10.75 23.2915 10.3468 23.2915 9.85413V4.47913C23.2915 3.98463 22.8884 3.58329 22.3957 3.58329H19.7082V10.75Z"
          fill={theme === 'light' ? '#101323' : '#ffffff'}
        />
        <path
          d="M27.7708 12.5416C27.2763 12.5416 26.875 12.1403 26.875 11.6458V2.68746C26.875 2.19117 27.2763 1.79163 27.7708 1.79163C28.2653 1.79163 28.6667 2.19117 28.6667 2.68746V11.6458C28.6667 12.1403 28.2653 12.5416 27.7708 12.5416Z"
          fill={theme === 'light' ? '#101323' : '#ffffff'}
        />
        <path
          d="M33.1458 12.5417C32.9434 12.5417 32.7391 12.4736 32.5725 12.3338L27.1975 7.85467C26.9915 7.68446 26.875 7.43184 26.875 7.16667C26.875 6.90151 26.9915 6.64888 27.1975 6.47867L32.5725 1.99951C32.9523 1.68238 33.5149 1.73255 33.8338 2.11238C34.151 2.49221 34.099 3.05659 33.721 3.37371L29.1701 7.16667L33.7192 10.9578C34.099 11.275 34.1492 11.8393 33.832 12.2192C33.6565 12.4306 33.402 12.5417 33.1458 12.5417Z"
          fill={theme === 'light' ? '#101323' : '#ffffff'}
        />
      </g>
      <defs>
        <clipPath id="clip0_2996_33329">
          <rect width="43" height="43" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function IntegrationSuccessIcon(props: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="12" cy="12" r="10" fill="#079455" />
      <path
        d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke={theme === 'light' ? '#ffffff' : '#1D2939'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function CopyIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.5 3H14.6C16.8402 3 17.9603 3 18.816 3.43597C19.5686 3.81947 20.1805 4.43139 20.564 5.18404C21 6.03969 21 7.15979 21 9.4V16.5M6.2 21H14.3C15.4201 21 15.9802 21 16.408 20.782C16.7843 20.5903 17.0903 20.2843 17.282 19.908C17.5 19.4802 17.5 18.9201 17.5 17.8V9.7C17.5 8.57989 17.5 8.01984 17.282 7.59202C17.0903 7.21569 16.7843 6.90973 16.408 6.71799C15.9802 6.5 15.4201 6.5 14.3 6.5H6.2C5.0799 6.5 4.51984 6.5 4.09202 6.71799C3.71569 6.90973 3.40973 7.21569 3.21799 7.59202C3 8.01984 3 8.57989 3 9.7V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.0799 21 6.2 21Z"
        stroke="#98A2B3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function CopySuccessIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M21 6L9 18L3 12" stroke="#4CAF50" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export function SdkMenuIcon(props: SVGProps<SVGSVGElement>) {
  const { theme } = useTheme();
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_4079_33315)">
        <path
          d="M12 24C11.936 24 11.87 23.987 11.81 23.962L3.31 20.462C3.122 20.385 3 20.202 3 20V13C3 12.724 3.224 12.5 3.5 12.5C3.776 12.5 4 12.723 4 13V19.665L12 22.959L20 19.665V13C20 12.724 20.224 12.5 20.5 12.5C20.776 12.5 21 12.723 21 13V20C21 20.202 20.878 20.385 20.69 20.462L12.19 23.962C12.13 23.987 12.064 24 12 24Z"
          fill={theme === 'light' ? '#475467' : '#ffffff'}
        />
        <path
          d="M0.50025 11.5C0.500273 11.5 0.500299 11.4999 0.500324 11.4998L0.50012 11.4999L0.500231 11.5M0.50025 11.5L0.498923 11.4981L0.500089 11.4999L0.499712 11.4997C0.499598 11.4997 0.499745 11.4997 0.499968 11.4999L0.50015 11.5001C0.500139 11.5001 0.500159 11.5001 0.500231 11.5M0.50025 11.5L0.500231 11.5M12.2237 12.6382L19.5006 9.00023L23.4994 11.4995C23.4994 11.4995 23.4994 11.4995 23.4994 11.4995C23.4995 11.4996 23.4996 11.4996 23.4996 11.4997L23.4996 11.5002L15.5042 15.4979C15.5018 15.499 15.5001 15.4995 15.4991 15.4998C15.4988 15.4997 15.4985 15.4996 15.4983 15.4994C15.4982 15.4994 15.4981 15.4993 15.4981 15.4993L12.3091 12.9928L12.0001 12.75L11.6911 12.9928L8.50213 15.4993C8.50207 15.4993 8.50201 15.4994 8.50195 15.4994C8.50168 15.4996 8.50141 15.4997 8.50107 15.4998C8.50008 15.4995 8.49839 15.499 8.49598 15.4979L0.500591 11.5002L0.500566 11.4997C0.500632 11.4996 0.500707 11.4996 0.500793 11.4995C0.500812 11.4995 0.500831 11.4995 0.500851 11.4995L4.50003 8.99999L4.5005 9.00016L11.7765 12.6382L12.0001 12.75L12.2237 12.6382ZM23.5 11.5C23.5001 11.5002 23.5001 11.5002 23.5 11.5C23.4999 11.5 23.4999 11.4999 23.4999 11.4998L23.5 11.5Z"
          stroke={theme === 'light' ? '#475467' : '#ffffff'}
        />
        <path
          d="M7.25 7H5.5C5.224 7 5 6.776 5 6.5C5 6.224 5.224 6 5.5 6H7.25C7.663 6 8 5.663 8 5.25C8 4.837 7.663 4.5 7.25 4.5H6.75C5.785 4.5 5 3.715 5 2.75C5 1.785 5.785 1 6.75 1H8.5C8.776 1 9 1.223 9 1.5C9 1.777 8.776 2 8.5 2H6.75C6.337 2 6 2.337 6 2.75C6 3.163 6.337 3.5 6.75 3.5H7.25C8.215 3.5 9 4.285 9 5.25C9 6.215 8.215 7 7.25 7Z"
          fill={theme === 'light' ? '#475467' : '#ffffff'}
        />
        <path
          d="M12.5 7H10.5C10.224 7 10 6.776 10 6.5V1.5C10 1.223 10.224 1 10.5 1H12.5C13.327 1 14 1.673 14 2.5V5.5C14 6.327 13.327 7 12.5 7ZM11 6H12.5C12.775 6 13 5.775 13 5.5V2.5C13 2.224 12.775 2 12.5 2H11V6Z"
          fill={theme === 'light' ? '#475467' : '#ffffff'}
        />
        <path
          d="M15.5 7C15.224 7 15 6.776 15 6.5V1.5C15 1.223 15.224 1 15.5 1C15.776 1 16 1.223 16 1.5V6.5C16 6.776 15.776 7 15.5 7Z"
          fill={theme === 'light' ? '#475467' : '#ffffff'}
        />
        <path
          d="M18.5 7C18.387 7 18.273 6.962 18.18 6.884L15.18 4.384C15.065 4.289 15 4.148 15 4C15 3.852 15.065 3.711 15.18 3.616L18.18 1.116C18.392 0.939 18.706 0.967 18.884 1.179C19.061 1.391 19.032 1.706 18.821 1.883L16.281 4L18.82 6.116C19.032 6.293 19.06 6.608 18.883 6.82C18.785 6.938 18.643 7 18.5 7Z"
          fill={theme === 'light' ? '#475467' : '#ffffff'}
        />
      </g>
      <defs>
        <clipPath id="clip0_4079_33315">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
